import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort, SortDirection } from '@angular/material/sort';
import { FilterService } from '@core/services';
import { FilterTable } from '@core/types/filter-table.type';
import { DialogCancelComponent } from '../dialog-cancel';
import { Filter } from '../input-filter';
import {ERoutesApi} from "@core/enums";

/**
 * @description
 * Componente das tabelas mais genéricas do sistemas.
 *
 * @param data - Parâmetro responsável por conter os dados que serão exibidos na tabela
 * @param totalItems - Parâmetro responsável por conter o total de itens para a paginação
 * @param isLoading - Parâmetro responsável por exibir o loading
 * @param headerOptions - Parâmetro responsável por conter as definições dos cabeçalhos e números de
 * colunas que serão exibidos na tabela. *Não é necessário passar a coluna de acoes*
 * @param hasCreate - Parâmetro responsável por informar se tem função de criar
 * @param hasDetail - Parâmetro responsável por informar se tem função de detalhar
 * @param hasEdit - Parâmetro responsável por informar se tem função de editar
 * @param hasDelete - Parâmetro responsável por informar se tem função de deletar
 * @param hasValidate - Parâmetro responsável por informar se tem função de validar
 * @param hasOtherAction - Parâmetro responsável por informar se tem função extra
 * @param showPesquisa - Parâmetro responsável por exibir ou não o cabeçalho com a pesquisa e os botões de exportar e criar
 * @param titleButtonValidate - Parâmetro responsável por informar o título do botão de validar
 * @param titleButtonOtherAction - Parâmetro responsável por informar o título do botão de função extra
 * @param iconButtonValidate - Parâmetro responsável por informar o nome do ícone do botão de validar
 * @param iconButtonOtherAction - Parâmetro responsável por informar o nome do ícone do botão função extra
 * @param isSortDisable - Parâmetro responsável por informar se tem ordenação na tabela
 * @param isPaginateDisable - Parâmetro responsável por informar se tem paginação na tabela
 *
 *
 * @callback handleOnClickRow - Callback para triggar a ação de clique na linha
 * @callback handleOrder - Callback para triggar a ordenação da coluna
 * @callback handleSearchByQuery - Callback para triggar a busca por texto digitado
 * @callback handlePaginate - Callback para triggar a paginacao
 * @callback handleCreate - Callback para triggar a função de criar novo objeto
 * @callback handleDetail - Callback para triggar a função de detalhar objeto
 * @callback handleEdit - Callback para triggar a função de editar objeto
 * @callback handleDelete - Callback para triggar a função de deletar objeto
 * @callback handleValidate - Callback para triggar a função de validar objeto
 * @callback handleOtherAction - Callback para triggar a função extra
 * @callback handleApplyFilterColumns - Callback para triggar a função de filtro por coluna
 *
 * @author Luan de Souza
 */

export type HeaderOptions = {
  description: string;
  attribute: string;
  attributeSelectorCheckbox?: string;
  mappedFor?: string;
  type?: HeaderOptionsTypes;
  typeFilter?: HeaderOptionsFilterTypes;
  isToFormatValue?: boolean;
  digitsInfo?: string;
  route?: string;
};

export type HeaderOptionsSearch = {
  description: string;
  attribute: string;
  attributeSelectorCheckbox?: string;
  route?: string;
  typeValue?: HeaderOptionsTypes;
  typeFilter?: HeaderOptionsFilterTypes;
};

export enum HeaderOptionsTypes {
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  DATE_TIME_MILLISECONDS = 'DATE_TIME_MILLISECONDS',
  TIME = 'TIME'
}

export const HeaderOptionsMaskMap = new Map<HeaderOptionsTypes, string>([
  [HeaderOptionsTypes.DATE_TIME_MILLISECONDS, 'dd/MM/yyyy HH:mm:ss'],
  [HeaderOptionsTypes.DATE_TIME, 'dd/MM/yyyy HH:mm'],
  [HeaderOptionsTypes.DATE, 'dd/MM/yyyy'],
  [HeaderOptionsTypes.TIME, ''],
  [HeaderOptionsTypes.NUMBER, '']
]);

export enum HeaderOptionsFilterTypes {
  INPUT = 'INPUT',
  SELECTOR_CHECKBOX = 'SELECTOR_CHECKBOX'
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'table-component',
  templateUrl: 'table.component.html',
  styleUrls: ['table.style.scss']
})
export class TableComponent implements OnInit, OnChanges {
  digitsInfoDefault = '1.4-4';
  isSearching = false;

  actualSort: Sort;
  beforeSort: Sort;
  isToSort = true;

  @Input() rota: string;
  @Input() query: string;
  @Input() data: any[];
  @Input() totalItems: number;
  @Input() isLoading: boolean;
  @Input() headerOptions: HeaderOptions[];
  @Input() subtitleFooter: string;
  @Input() pageOptions = [10, 15, 20, 25, 30];
  @Input() paginaAtual = 0;
  @Input() pageSize = 10;
  @Input() fileNameExport: string;
  @Input() pathExport: string;
  @Input() pathExportCustomized: boolean;
  @Input() paramsToExportFile: Map<string, string>;
  @Input() selectionCheckbox = new SelectionModel<any>(true, []);
  @Input() hasBadgeImport = false;
  @Input() hasImport = false;
  @Input() hasCreate = false;
  @Input() hasDetail = false;
  @Input() hasEdit = false;
  @Input() hasDelete = false;
  @Input() hasValidate = false;
  @Input() hasOtherAction = false;
  @Input() hasExportFile = false;
  @Input() showPesquisa = true;
  @Input() showFiltro = true;
  @Input() badgeImport: number;
  @Input() titleButtonImport = 'Importar';
  @Input() titleButtonCreate = 'Inserir';
  @Input() titleButtonEdit = 'Editar';
  @Input() titleButtonDelete = 'Deletar';
  @Input() titleButtonDetail = 'Visualizar';
  @Input() titleButtonValidate = 'Validar';
  @Input() titleButtonOtherAction = 'Outro';
  @Input() iconButtonValidate = '';
  @Input() iconButtonOtherAction = '';
  @Input() colorButtonValidate = '';
  @Input() colorButtonOtherAction = '';
  @Input() isSortDisable = false;
  @Input() isPaginateDisable = false;
  @Input() isFilterEnable = false;
  @Input() isHeaderLarge = false;
  @Input() hasSelectionCheckbox = false;
  @Input() hasColumnComentarios = false;
  @Input() tipoTabela!: string;

  @Output() handleOnClickRow = new EventEmitter();
  @Output() handleOrder = new EventEmitter<string>();
  @Output() handleSearchByQuery = new EventEmitter<FilterTable>();
  @Output() handleSearchByFilter = new EventEmitter<FilterTable>();
  @Output() handlePaginate = new EventEmitter<PageEvent>();
  @Output() handleCreate = new EventEmitter();
  @Output() handleImport = new EventEmitter();
  @Output() handleDetail = new EventEmitter();
  @Output() handleEdit = new EventEmitter();
  @Output() handleDelete = new EventEmitter();
  @Output() handleValidate = new EventEmitter();
  @Output() handleOtherAction = new EventEmitter();
  @Output() handleApplyFilterColumns = new EventEmitter<string>();
  @Output() handleSearchData = new EventEmitter<FilterTable>();
  @Output() handleClearFilters = new EventEmitter();

  headerOptionsAttributeSearch: HeaderOptionsSearch[];

  order = '';
  offset = 0;

  filterColumns: Map<string, Filter[]>;
  filter: FilterTable;

  constructor(private readonly filterService: FilterService, private readonly matDialog: MatDialog) {
    this.filterColumns = new Map();
  }

  ngOnInit(): void {
    if (this.headerOptions?.length > 0) {
      this.headerOptionsAttributeSearch = this.headerOptions.map<HeaderOptionsSearch>((c) => {
        return {
          description: c.attribute + '_search',
          typeValue: c.type,
          attribute: c.attribute,
          typeFilter: c.typeFilter,
          route: c.route,
          attributeSelectorCheckbox: c.attributeSelectorCheckbox
        };
      });
    }
    this._inicializarFiltrosTabela();
    if (this.data === null || this.data === undefined || this.data.length <= 0) {
      this.handleSearchData.emit(this.filter);
    }
  }

  ngOnChanges(): void {
    if (this.data.length > 0 && this.rota) {
      this._inicializarFiltrosTabela();
      this._updateSelectionCheckbox(this.filter.selection);
    }
  }

  get _headerOptionsAttributes() {
    let headers = [];
    if (this.hasSelectionCheckbox) {
      headers = ['inicio', 'checkbox', ...this.headerOptions.map((h) => h.attribute), 'fim'];
      this.hasAnyAction() ? headers.splice(2, 0, 'acoes') : '';
    } else {
      headers = ['inicio', ...this.headerOptions.map((h) => h.attribute), 'fim'];
      this.hasAnyAction() ? headers.splice(1, 0, 'acoes') : '';
    }
    this.hasColumnComentarios ? headers.splice(headers.length - 1, 0, 'comentarios') : '';
    return headers;
  }

  get _headerOptionsAttributesSearch() {
    let headers = [];
    if (this.hasSelectionCheckbox) {
      headers = ['checkboxSearch', 'inicioSearch', ...this.headerOptionsAttributeSearch.map((h) => h.description), 'fimSearch'];
      this.hasAnyAction() ? headers.splice(2, 0, 'acoesSearch') : '';
    } else {
      headers = ['inicioSearch', ...this.headerOptionsAttributeSearch.map((h) => h.description), 'fimSearch'];
      this.hasAnyAction() ? headers.splice(1, 0, 'acoesSearch') : '';
    }
    this.hasColumnComentarios ? headers.splice(headers.length - 1, 0, 'comentariosSearch') : '';
    return headers;
  }

  hasAnyAction() {
    return (
      this.hasDetail ||
      this.hasEdit ||
      this.hasDelete ||
      this.hasValidate ||
      this.hasOtherAction ||
      this.data.find((element) => element.display_info_criacao !== null) !== undefined
    );
  }

  isAllSelected() {
    return this.selectionCheckbox.selected.length === this.data.length;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selectionCheckbox.clear();
    } else {
      this.selectionCheckbox.select(...this.data);
    }

    this.handleApplySelectionCheckbox();
  }

  toggleOneRow(row: any) {
    this.selectionCheckbox.toggle(row);
    this.handleApplySelectionCheckbox();
  }

  handleApplySelectionCheckbox() {
    this.filter.selection = this.selectionCheckbox.selected.map((data) => data.id);
    this._updateFilterTable();
  }

  handleApplyFilterColumn(elements: Filter[]) {
    this.filterColumns.set(elements[0].column, elements);
    this.filter.offset = 0;
    this.paginaAtual = 0;
    this.filter.otherFilters = Array.from(this.filterColumns.values()).flatMap((value) => value);
    this._updateFilterTable();
    this.handleSearchData.emit(this.filter);
  }

  handleCleanFilterColumn(element: string) {
    this.filterColumns.delete(element);
    this.filter.offset = 0;
    this.paginaAtual = 0;
    this.filter.otherFilters = Array.from(this.filterColumns.values()).flatMap((value) => value);
    if (this.filterColumns.size <= 0) {
      this.isFilterEnable = false;
    }
    this._updateFilterTable();
    this.handleSearchData.emit(this.filter);
  }

  handleEnableFilterColumns() {
    this.isFilterEnable = !this.isFilterEnable;
  }

  handleClearQuery() {
    this.query = '';
    this.filter.query = '';
    if (this.isSearching) {
      this.isSearching = false;
      this.paginaAtual = 0;
      this.filter.offset = 0;
      this._updateFilterTable();
      this.handleSearchData.emit(this.filter);
    }
  }

  _handleClearFilters() {
    const ref = this.matDialog.open(DialogCancelComponent, {
      data: { title: 'Tem certeza que deseja limpar todos os filtros?' }
    });
    ref.afterClosed().subscribe((isAgreed) => {
      if (isAgreed) {
        this._handleClearAllFilters();
        if (this.handleClearFilters) {
          this.handleClearFilters.emit();
        }
      }
    });
  }

  _handleOnClickRow(element: number | string | any) {
    this.handleOnClickRow.emit(element);
  }

  _handlePageEvent(event: PageEvent) {
    this.paginaAtual = event.pageIndex;
    this.filter.offset = event.pageSize * event.pageIndex;
    this.filter.limit = event.pageSize;
    this.pageSize = event.pageSize;
    this._updateFilterTable();
    this._updateOrder(this.filter.order);
    this._updateColumnsFilters(this.filter);
    this.handleSearchData.emit(this.filter);
  }

  _handleCreate() {
    this.handleCreate.emit();
  }

  _handleImport() {
    this.handleImport.emit();
  }

  _handleDetail(element: number | string | any) {
    if(this.tipoTabela) window.location.href = `/${this.tipoTabela}/details?id=${element}`
  }

  _handleEdit(element: number | string | any) {
    this.handleEdit.emit(element);
  }

  _handleDelete(element: number | string | any) {
    this.handleDelete.emit({ id: element, filter: this.filter });
  }

  _handleValidate(element: number | string | any) {
    this.handleValidate.emit(element);
  }

  _handleOtherAction(element: number | string | any) {
    this.handleOtherAction.emit({ data: element, filter: this.filter });
  }

  _handleSort(sort: Sort) {
    const { active, direction } = sort;

    this.actualSort = sort;
    let correctSignal: string;

    switch (direction) {
      case 'desc': {
        correctSignal = '-';
        break;
      }
      default: {
        correctSignal = '';
        break;
      }
    }

    if (this.isToSort || this.actualSort.active !== this.beforeSort.active) {
      this.isToSort = direction === 'desc' && this.actualSort.active === this.beforeSort.active ? false : true;
      this.filter.order = `${correctSignal}${active}`;
    } else {
      this.isToSort = true;
      this.actualSort = null;
      this.filter.order = '';
    }
    this._updateFilterTable();
    this.handleSearchData.emit(this.filter);
    this.beforeSort = sort;
  }

  _handleFetchByQuery() {
    this.filter.query = this.query;
    if (this.filter.query) {
      this.isSearching = true;
    } else {
      this.isSearching = false;
    }
    this.paginaAtual = 0;
    this.filter.offset = 0;
    this.filterService.saveFilter(
      this.rota,
      this.filter.limit,
      this.offset,
      this.filter.order,
      this.filter.query,
      this.filter.otherFilters,
      this.filter.selection
    );
    if (this.handleSearchByQuery) {
      this.handleSearchByQuery.emit(this.filter);
    }
    if (this.handleSearchData) {
      this.handleSearchData.emit(this.filter);
    }
  }

  _handleCheckSortActive() {
    if (this.isSortDisable) return '';
    if (this.actualSort?.active) return this.actualSort.active;
    return 'asc';
  }

  _handleCheckSortDirection() {
    if (this.isSortDisable) return '';
    if (this.actualSort?.direction) return this.actualSort.direction;
    return 'asc';
  }

  private _inicializarFiltrosTabela() {
    if (this.rota) {
      this.filter = this.filterService.recoverFilter(this.rota);
      if (this.filter !== null && this.filter !== undefined) {
        if (this.filter.query) {
          this.isSearching = true;
        }
        this.paginaAtual = this.filter.offset / this.filter.limit;
        this.pageSize = this.filter.limit;
        this.query = this.filter.query;
        this._updateOrder(this.filter.order);
        this._updateColumnsFilters(this.filter);
      } else {
        this.filterService.saveFilter(this.rota, this.pageSize, 0, '', '', [], []);
        this.filter = this.filterService.recoverFilter(this.rota);
      }
    } else {
      this.filter = { limit: this.pageSize, offset: 0, order: '', query: '', otherFilters: [], selection: [] };
    }
  }

  private _updateFilterTable() {
    if (this.rota) {
      this.filterService.saveFilter(
        this.rota,
        this.filter.limit,
        this.filter.offset,
        this.filter.order,
        this.filter.query,
        this.filter.otherFilters,
        this.filter.selection
      );
      this.filter = this.filterService.recoverFilter(this.rota);
    }
  }

  private _updateOrder(order: string) {
    if (order && !this.isSortDisable) {
      const direction = order?.at(0) === '-' ? ('desc' as SortDirection) : ('asc' as SortDirection);
      const active = direction === 'desc' ? order?.substring(1, order?.length) : order.substring(0, order.length);
      this.actualSort = { active, direction };
      this.beforeSort = { active, direction };
    }
    if (this.isSortDisable) {
      this.actualSort = {
        active: undefined,
        direction: ''
      };
      this.beforeSort = {
        active: undefined,
        direction: ''
      };
    }
  }

  private _updateColumnsFilters(filterTable: FilterTable) {
    this.filterColumns = new Map<string, Filter[]>();
    filterTable.otherFilters.forEach((v) => {
      if (!this.filterColumns.has(v.column)) {
        this.filterColumns.set(v.column, [v]);
      } else {
        this.filterColumns.get(v.column).push(v);
      }
    });
    if (this.filterColumns.size > 0) {
      this.isFilterEnable = true;
    } else {
      this.isFilterEnable = false;
    }
  }

  private _updateSelectionCheckbox(selectionCache: any[]) {
    const selection = this.data.filter((objeto) => selectionCache.includes(objeto.id));
    if (selection.length > 0) {
      this.selectionCheckbox.select(...selection);
    } else {
      this.selectionCheckbox.clear();
    }
  }

  private _handleClearAllFilters() {
    this.query = '';
    this.pageSize = 10;
    this.order = '';
    this.isSearching = false;
    this.isFilterEnable = false;
    this.actualSort = { active: undefined, direction: '' };
    this.filterColumns = new Map<string, Filter[]>();
    this.selectionCheckbox.clear();
    this.filterService.saveFilter(this.rota, this.pageSize, 0, this.order, this.query, [], []);
    this.filter = this.filterService.recoverFilter(this.rota);
    this.paginaAtual = 0;
    this._updateFilterTable();
    this.handleSearchData.emit(this.filter);
  }

  protected readonly ERoutesApi = ERoutesApi;
}
