<div mat-dialog-title class="mt-4 flex items-center">
  <span class="mr-2 flex items-center rounded bg-orange-400 px-3 py-1 text-white text-white">
    <span class="material-icons">warning</span>
  </span>
  <h1 class="text-2xl">{{ data.title }}</h1>
</div>
<div mat-dialog-content class="w-full">
  <span>{{ data.message }}</span>
</div>
<div mat-dialog-actions class="flex items-center gap-4">
  <button type="button" class="base-btn bg-gray-200 hover:bg-gray-300" (click)="handleCancel()">Não</button>
  <button type="button" class="base-btn bg-orange-500 text-white hover:bg-orange-600" (click)="handleConfirm()">Sim</button>
</div>
