import { Route } from '@angular/router';
import { ERoutes } from '@core/enums';
import { AuthGuard, PublicGuard } from '@core/guards';
import { AlterarSenhaComponent } from '@features/auth/pages/alterar-senha/alterar-senha.component';

export const AppRoutes: Route[] = [
  {
    path: '',
    redirectTo: ERoutes.HOME,
    pathMatch: 'full'
  },
  {
    path: ERoutes.PATH_AUTH,
    loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [PublicGuard]
  },
  {
    path: ERoutes.ALTERAR_SENHA,
    component: AlterarSenhaComponent,
    title: 'Alterar Senha',
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/empresas/empresas.module').then((m) => m.EmpresasModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/cargos/cargos.module').then((m) => m.CargosModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/campos/campos.module').then((m) => m.CamposModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/pt/pt.module').then((m) => m.PtModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/apr/apr.module').then((m) => m.AprModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: ERoutes.PATH_PRIVATE,
  //   loadChildren: () => import('./features/cluster/cluster.module').then((m) => m.ClusterModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/perfis/perfis.module').then((m) => m.PerfisModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/colaboradores/colaboradores.module').then((m) => m.ColaboradoresModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/usuarios/usuarios.module').then((m) => m.UsuariosModule),
    canActivate: [AuthGuard]
  }
];
