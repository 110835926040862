import { Injectable } from '@angular/core';
import { EPermissions, ERoutes } from '@core/enums';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  permissionsByRoute: Map<string, EPermissions[]>;

  constructor(private readonly authService: AuthService) {
    this.initPermissionsByRoute();
  }

  /**
   * Verifica se o usuário logado possui uma determinada permissão.
   *
   * @param {EPermissions} permission a permissão a ser verifica
   * @returns {boolean} true caso o usuário possua a permissão desejada
   */

  userHasPermission(permission: EPermissions): boolean {
    const loggedUser = this.authService.loggedUser();

    if (!loggedUser) {
      return false;
    } else {
      const permissions = loggedUser.permissoes;
      return permissions.find((p) => p === permission) !== undefined ? true : false;
    }
  }

  userHasPermissions(permissions: EPermissions[]): boolean {
    const loggedUser = this.authService.loggedUser();
    let allow = false;

    if (!loggedUser) {
      allow = false;
    } else {
      const permissionsUser: EPermissions[] = loggedUser.permissoes as EPermissions[];
      permissions.forEach((p) => {
        if (permissionsUser.includes(p)) {
          allow = true;
        }
      });
    }
    return allow;
  }

  userHasPermissionRoute(url: string): boolean {
    const loggedUser = this.authService.loggedUser();
    let allow = false;

    if (loggedUser) {
      url = url.replace(/[0-9]/g, '');

      const permissionsUser: EPermissions[] = loggedUser.permissoes as EPermissions[];

      if (this.permissionsByRoute.has(url)) {
        const permissionsRoute: EPermissions[] = this.permissionsByRoute.get(url);

        if (permissionsUser != null && permissionsUser.length > 0) {
          permissionsUser.forEach((permissionUser) => {
            if (permissionsRoute.includes(permissionUser)) {
              allow = true;
            }
          });
        }
      } else {
        allow = true;
      }
    }

    return allow;
  }

  // prettier-ignore
  private initPermissionsByRoute() {
    this.permissionsByRoute = new Map<string, EPermissions[]>();

    /**
     *
     * Permissões da aba
     * APR
     *
     */
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.APR}/${ERoutes.CRIAR}`, [EPermissions.ROLE_CADASTRO_APR_CADASTRAR]);

    /**
     *
     * Permissões da aba
     * GESTÃO DA PRODUÇÃO
     *
     */
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CAMPO}/${ERoutes.CRIAR}`, [EPermissions.ROLE_CADASTRO_CAMPO_CADASTRAR]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CAMPO}/${ERoutes.EDITAR}/`, [EPermissions.ROLE_CADASTRO_CAMPO_EDITAR]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CAMPO}/${ERoutes.DETALHAR}/`, [EPermissions.ROLE_CADASTRO_CAMPO_LISTAR]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CAMPO}`, [EPermissions.ROLE_CADASTRO_CAMPO_LISTAR]);

    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CLUSTER}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_CLUSTER]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CLUSTER}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_CLUSTER]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CLUSTER}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_CLUSTER]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CLUSTER}`, [EPermissions.VISUALIZAR_CLUSTER]);


    /**
     *
     * Permissões da aba
     * ADMINISTRAÇÃO
     *
     */
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CARGO}/${ERoutes.CRIAR}`, [EPermissions.ROLE_CADASTRO_CARGO_CADASTRAR]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CARGO}/${ERoutes.EDITAR}/`, [EPermissions.ROLE_CADASTRO_CARGO_EDITAR]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CARGO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_CARGO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CARGO}`, [EPermissions.ROLE_CADASTRO_CARGO_LISTAR]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.COLABORADOR}/${ERoutes.CRIAR}`, [EPermissions.ROLE_CADASTRO_PESSOA_CADASTRAR]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.COLABORADOR}/${ERoutes.EDITAR}/`, [EPermissions.ROLE_CADASTRO_PESSOA_EDITAR]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.COLABORADOR}/${ERoutes.DETALHAR}/`, [EPermissions.ROLE_CADASTRO_PESSOA_LISTAR]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.COLABORADOR}`, [EPermissions.ROLE_CADASTRO_PESSOA_LISTAR]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.EMPRESA}/${ERoutes.CRIAR}`, [EPermissions.ROLE_CADASTRO_EMPRESA_CADASTRAR]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.EMPRESA}/${ERoutes.EDITAR}/`, [EPermissions.ROLE_CADASTRO_EMPRESA_EDITAR]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.EMPRESA}/${ERoutes.DETALHAR}/`, [EPermissions.ROLE_CADASTRO_EMPRESA_LISTAR]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.EMPRESA}`, [EPermissions.ROLE_CADASTRO_EMPRESA_LISTAR]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PERFIL}/${ERoutes.CRIAR}`, [EPermissions.ROLE_CADASTRO_PERFIL_CADASTRAR]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PERFIL}/${ERoutes.EDITAR}/`, [EPermissions.ROLE_CADASTRO_PERFIL_EDITAR]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PERFIL}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_PERFIL]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PERFIL}`, [EPermissions.ROLE_CADASTRO_PERFIL_LISTAR]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.USUARIO}/${ERoutes.CRIAR}`, [EPermissions.ROLE_CADASTRO_USUARIO_CADASTRAR]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.USUARIO}/${ERoutes.EDITAR}/`, [EPermissions.ROLE_CADASTRO_USUARIO_EDITAR]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.USUARIO}/${ERoutes.DETALHAR}/`, [EPermissions.ROLE_CADASTRO_USUARIO_LISTAR]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.USUARIO}`, [EPermissions.ROLE_CADASTRO_USUARIO_LISTAR]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PT}`, [EPermissions.ROLE_CADASTRO_PT_LISTAR]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PT}/${ERoutes.DETALHAR}/`, [EPermissions.ROLE_CADASTRO_PT_LISTAR]);
  }
}
