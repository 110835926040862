import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from './message.service';

@Injectable({ providedIn: 'root' })
export class CrudErrorService {
  constructor(private readonly messageService: MessageService) {}

  handleErrorAPIWithNames(error: HttpErrorResponse, names: string[], titleDefault: string) {
    if (Array.isArray(error.error)) {
      return this.messageService.showError(error.error[0], titleDefault);
    } else if (typeof error.error === 'object') {
      names.forEach((name) => {
        if (error.error[name] !== undefined) {
          return this.messageService.showError(error.error[name][0], titleDefault);
        }
      });
    } else {
      return this.messageService.showError('Erro não esperado aconteceu. Entre em contato com o suporte.', titleDefault);
    }
  }

  handleErrorAPI(error: HttpErrorResponse, titleDefault: string) {
    console.error(error);
    if (error.status === 502 || error.status === 0) {
      return;
    } else if (error.status === 401) {
      this.messageService.showError(error.error.detail, 'Sessão Expirada');
    } else if (error.status === 413) {
      this.messageService.showError(error.error.detail, 'O arquivo submetido é muito grande');
    } else if (Array.isArray(error.error)) {
      return this.messageService.showError(error.error[0], titleDefault);
    } else if (typeof error.error === 'string') {
      if (error.error.length > 200) {
        this.messageService.showError(error.error.substring(0, 199) + '...', titleDefault);
      } else {
        this.messageService.showError(error.error, titleDefault);
      }
    } else if (typeof error.error === 'object') {
      if (Object.keys(error.error).length <= 1) {
        const message: any = Object.values(error.error)[0];
        this.messageService.showError(message.join('\n'), titleDefault);
      } else {
        for (const key in Object.keys(error.error)) {
          const message: any = Object.values(error.error)[key];
          this.messageService.showError(message[0]);
        }
        this.messageService.showError('', titleDefault);
      }
    } else {
      return this.messageService.showError('Erro não esperado aconteceu. Entre em contato com o suporte.', titleDefault);
    }
  }
}
