import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ERoutes } from '@core/enums';
import { AuthService } from '@core/services';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PublicGuard implements CanActivate {
  constructor(private readonly service: AuthService, private readonly router: Router) {}

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const token = this.service.getToken();
    if (token) {
      return this.router.createUrlTree([`${ERoutes.PATH_PRIVATE}`]);
    }
    return true;
  }
}
