import { Injectable } from '@angular/core';
import { ERoutesApi } from '@core/enums';
import { GenericCrudService } from '@core/services';
import { Cargo } from '../models';

@Injectable({ providedIn: 'root' })
export class CargoService extends GenericCrudService<Cargo, Cargo> {
  constructor() {
    super(ERoutesApi.CARGOS);
  }
}
