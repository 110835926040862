import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ERoutesApi } from '@core/enums';
import { AuthService, MessageService } from '@core/services';
import { environment } from 'environments/environment';
import messages from 'locale/messages.pt';
import { Observable, catchError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private urlToNotIntercept = [
    `${environment.apiUrl}/${ERoutesApi.AUTH}/`,
    `${environment.apiUrl}/${ERoutesApi.TOKEN_REFRESH}/`,
    `${environment.apiUrl}/${ERoutesApi.AMBIENTE}/`,
    `${environment.apiUrl}/${ERoutesApi.USUARIOS}/${ERoutesApi.RECUPERAR_SENHA}`,
    `${environment.apiUrl}/${ERoutesApi.USUARIOS}/${ERoutesApi.RECUPERAR_SENHA_CODIGO}`
  ];

  constructor(private readonly authService: AuthService, private readonly messageService: MessageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isValidRequestForInterceptor(req.url)) {
      // this.authService.verifyToken(); // Desativar enquanto essa lógica não for implementada na API
      return next.handle(req).pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.authService.signOut();
              this.messageService.showAlert(messages['http.status.401.message'], messages['http.status.401.title']);
            }
          }
          throw err;
        })
      );
    }

    return next.handle(req);
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    for (const address of this.urlToNotIntercept) {
      if (new RegExp(address).test(requestUrl)) {
        return false;
      }
    }

    return true;
  }
}
