import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { Subject, Subscription, debounceTime } from 'rxjs';

const INPUT_FIELD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputComponent),
  multi: true
};

@Component({
  selector: 'input-component',
  templateUrl: 'input.component.html',
  standalone: true,
  imports: [NgxMaskDirective, ReactiveFormsModule, FormsModule, CommonModule],
  providers: [INPUT_FIELD_VALUE_ACCESSOR, provideNgxMask()]
})
export class InputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() label: string;
  @Input() id: string;
  @Input() type: string;
  @Input() step: string;
  @Input() control: AbstractControl;
  @Input() isReadonly = false;
  @Input() submitted = false;
  @Input() isDecimalValue = false;
  @Input() digitsInfo: string;
  @Input() debounceValue: number;
  @Input() mask: string;

  @Output() currentValue = new EventEmitter();
  @Output() onBlur = new EventEmitter<string | number>();
  @Output() onKeyPress = new EventEmitter<string | number>();

  inputSubject: Subject<string> = new Subject<string>();
  debounceSubscription: Subscription;

  private _innerValue: string;

  get value() {
    return this._innerValue;
  }

  set value(v: string) {
    if (this._innerValue !== v) {
      this._innerValue = v;
      this.onChangeCb(v);
    }
  }

  ngOnInit(): void {
    this.debounceSubscription = this.inputSubject
      .asObservable()
      .pipe(debounceTime(this.debounceValue))
      .subscribe(() => this.currentValue.emit(this._innerValue));
  }

  ngOnDestroy(): void {
    this.debounceSubscription.unsubscribe();
  }

  onChangeCb: (_: any) => void = () => {};
  onTouchedCb: (_: any) => void = () => {};

  writeValue(value: any): void {
    if (value !== this._innerValue) {
      if (value !== null && this.isDecimalValue) {
        this.value = new DecimalPipe('pt').transform(Number(value.toString().replace(',', '.')), this.digitsInfo);
      } else {
        this.value = value;
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  _handleChange() {
    this.inputSubject.next(this._innerValue);
  }

  _onBlur() {
    this.onBlur.emit(this._innerValue);
  }

  _onKeyPress() {
    this.onKeyPress.emit(this._innerValue);
  }

  checkMax() {
    if (this.type === 'datetime-local') {
      return '9999-12-31T23:59';
    }
    if (this.type === 'date') {
      return '9999-12-31';
    }
    return null;
  }

  objectHelp = Object;
}
