import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';

import {ChangelogModalComponent, NavbarComponent, SidebarComponent} from './components';
import { AuthGuard, PublicGuard } from './guards';
import { AuthInterceptor } from './interceptors';
import { AppLayout, MainLayout } from './layout';
import {
  AuthService,
  CrudErrorService,
  CrudService,
  EnvService,
  FilterService,
  LocalStorageService,
  MessageService,
  SidebarService
} from './services';
import { InputDinamicoQuestionarioComponent } from './components/input-dinamico-questionario/input-dinamico-questionario.component';

@NgModule({
  imports: [CommonModule, HttpClientModule, SharedModule, RouterModule],
  providers: [
    AuthService,
    CrudService,
    LocalStorageService,
    FilterService,
    AuthGuard,
    AuthInterceptor,
    CrudErrorService,
    MessageService,
    SidebarService,
    PublicGuard,
    EnvService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  declarations: [NavbarComponent, SidebarComponent, MainLayout, AppLayout, ChangelogModalComponent, InputDinamicoQuestionarioComponent],
  exports: [MainLayout, NavbarComponent, SidebarComponent, AppLayout, InputDinamicoQuestionarioComponent]
})
export class CoreModule {}
