<aside
  class="fixed left-0 top-0 z-[9999] h-screen min-h-screen bg-neutro-100 pb-8 transition-all duration-300"
  [ngClass]="isSidebarOpen ? 'w-3/12 xs:w-10/12 sm:w-8/12 md:w-4/12 xl:w-2/12 2xl:w-2/12' : 'w-[6rem] xs:w-2/12'"
  *ngIf="isLoggedUser"
  on-mouseover="mouseHoverInside('in')"
  on-mouseleave="mouseHoverInside('out')"
>
  <div class="flex h-full w-full flex-col items-center justify-center p-3">
    <div class="flex w-full flex-col items-center justify-center">
      <app-logo [isToggle]="isSidebarOpen" routerLink="/home" class="cursor-pointer"></app-logo>

      <div class="mt-[1.06rem] flex w-full flex-row items-center gap-4" [ngClass]="isSidebarOpen ? ' justify-between' : 'justify-center'">
        <hr class="w-full border-primario-700" />
        <button
          [title]="isSidebarOpen ? 'Fechar' : 'Expandir'"
          type="button"
          (click)="handleSidebar()"
          class="flex h-8 w-8 cursor-pointer flex-row items-center justify-center rounded-full bg-primario-700 text-branco hover:opacity-90"
        >
          <span
            class="material-icons flex w-5/12 items-center justify-center text-xl"
            [ngClass]="isSidebarOpen ? '-scale-x-100' : 'scale-x-100'"
            >chevron_left</span
          >
          <span
            class="material-icons flex w-5/12 items-center justify-center text-xl"
            [ngClass]="isSidebarOpen ? '-scale-x-100' : 'scale-x-100'"
            >chevron_right</span
          >
        </button>
        <hr class="w-full border-primario-700" />
      </div>

      <form class="mt-4 w-11/12" [ngClass]="isSidebarOpen ? 'block' : 'hidden'">
        <div class="input-wrapper mt-2 mb-6">
          <input
            placeholder="Pesquisar"
            type="text"
            class="input-base bg-transparent"
            [(ngModel)]="textSearch"
            id="pesquisar-menu"
            name="pesquisar-menu"
            autocomplete="off"
          />
        </div>
      </form>
    </div>

    <div class="h-[98%] w-full overflow-y-auto scrollbar-thin scrollbar-trabg-neutro-100 scrollbar-thumb-gray-300" *ngIf="isSidebarOpen">
      <mat-accordion *ngFor="let option of filteredRoutes">
        <mat-panel-description class="w-full">
          <mat-panel-title class="w-full">
            <a
              [id]="option.id"
              *ngIf="!option.hasSubtopics && !option.hasTopics && option.routeName"
              [routerLink]="option.routeName"
              (click)="handleClickModule(null)"
              class="flex w-full grow-0 cursor-pointer items-center gap-x-[1.3rem] rounded px-6 py-3 font-sans text-base font-medium text-primario-700 hover:bg-neutro-200"
            >
              <span class="material-icons">{{ option.iconName }}</span>
              {{ option.moduleName }}
            </a>

            <a
              [id]="option.id"
              *ngIf="!option.hasSubtopics && !option.hasTopics && !option.routeName"
              class="flex w-full cursor-default items-center justify-between rounded px-6 py-3 font-sans text-base font-medium text-primario-700 hover:bg-neutro-200"
            >
              <div class="flex items-center">
                <span class="material-icons">{{ option.iconName }}</span> {{ option.moduleName }}
              </div>
              <span class="text-xs font-light italic text-gray-400">em breve</span>
            </a>
          </mat-panel-title>
        </mat-panel-description>

        <mat-expansion-panel
          class="my-0 bg-transparent py-0 text-primario-700 shadow-none"
          [expanded]="textSearch ? option.isOpen : option.moduleName === selectedModule"
          [id]="option.id"
          *ngIf="option.isVisible"
        >
          <mat-expansion-panel-header (click)="handleClickModule(option.moduleName)" *ngIf="option.hasSubtopics || option.hasTopics">
            <mat-panel-title class="grow-0 text-primario-700">
              <span class="material-icons">{{ option.iconName }}</span>
            </mat-panel-title>

            <mat-panel-description class="font-sans text-base text-primario-700">{{ option.moduleName }}</mat-panel-description>
          </mat-expansion-panel-header>

          <ng-container *ngIf="option.hasTopics">
            <mat-accordion *ngFor="let topic of option.topics">
              <mat-expansion-panel
                [id]="topic.id"
                *ngIf="topic.isVisible"
                class="my-0 ml-6 bg-transparent shadow-none"
                [expanded]="textSearch ? topic.isOpen : option.moduleName === selectedModule && topic.moduleName === selectedSubModule"
              >
                <mat-expansion-panel-header id="topic.id" (click)="handleClickSubModule(topic.moduleName)">
                  <mat-panel-description class="font-sans font-normal text-primario-700">{{ topic.moduleName }}</mat-panel-description>
                </mat-expansion-panel-header>

                <mat-accordion *ngFor="let topicInside of topic.topics">
                  <mat-expansion-panel
                    [id]="topicInside.id"
                    *ngIf="topicInside.isVisible"
                    class="my-0 bg-transparent shadow-none"
                    [expanded]="
                      textSearch
                        ? topicInside.isOpen
                        : option.moduleName === selectedModule &&
                          topic.moduleName === selectedSubModule &&
                          topicInside.moduleName === selectedSubModuleInside
                    "
                  >
                    <mat-expansion-panel-header id="topicInside.id" (click)="handleClickSubModuleInside(topicInside.moduleName)">
                      <mat-panel-description class="w-3/5 font-sans font-normal text-primario-700">
                        {{ topicInside.moduleName }}</mat-panel-description
                      >
                    </mat-expansion-panel-header>

                    <mat-panel-description class="m-0 flex w-full p-0 font-sans" *ngFor="let subtopic of topicInside.subtopics">
                      <a
                        [id]="subtopic.id"
                        *ngIf="subtopic.isVisible && subtopic.routeName; else subTopicEmBreve"
                        [routerLink]="subtopic.routeName"
                        (click)="cleanFilter(subtopic.routeName)"
                        class="w-full cursor-pointer rounded px-4 py-3 text-primario-700 hover:bg-neutro-200"
                        >{{ subtopic.name }}
                      </a>

                      <ng-template #subTopicEmBreve>
                        <a
                          [id]="subtopic.id"
                          *ngIf="subtopic.isVisible"
                          class="flex w-full cursor-default flex-row items-center justify-between rounded px-4 py-3 text-primario-700 hover:bg-neutro-200"
                        >
                          <span>{{ subtopic.name }}</span>
                          <span class="text-xs font-light italic text-gray-400">em breve</span>
                        </a>
                      </ng-template>
                    </mat-panel-description>
                  </mat-expansion-panel>
                </mat-accordion>

                <mat-panel-description class="m-0 flex w-full p-0 font-sans" *ngFor="let subtopic of topic.subtopics">
                  <a
                    [id]="subtopic.id"
                    *ngIf="subtopic.isVisible && subtopic.routeName; else subTopicEmBreve"
                    [routerLink]="subtopic.routeName"
                    (click)="cleanFilter(subtopic.routeName)"
                    class="w-full cursor-pointer rounded px-4 py-3 pl-6 text-primario-700 hover:bg-neutro-200"
                    >{{ subtopic.name }}
                  </a>

                  <ng-template #subTopicEmBreve>
                    <a
                      [id]="subtopic.id"
                      *ngIf="subtopic.isVisible"
                      class="flex w-full cursor-default flex-row items-center justify-between rounded px-4 py-3 text-primario-700 hover:bg-neutro-200"
                    >
                      <span>
                        {{ subtopic.name }}
                      </span>
                      <span class="text-xs font-light italic text-gray-400">em breve</span>
                    </a>
                  </ng-template>
                </mat-panel-description>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>

          <mat-panel-description class="m-0 font-sans" *ngFor="let subtopic of option.subtopics">
            <a
              [id]="subtopic.id"
              *ngIf="subtopic.isVisible && subtopic.routeName; else subTopicEmBreve"
              [routerLink]="subtopic.routeName"
              (click)="cleanFilter(subtopic.routeName)"
              class="ml-6 w-full cursor-pointer rounded px-6 py-3 text-primario-700 hover:bg-neutro-200"
              >{{ subtopic.name }}
            </a>

            <ng-template #subTopicEmBreve>
              <a
                [id]="subtopic.id"
                *ngIf="subtopic.isVisible"
                class="ml-6 flex w-full cursor-default items-center justify-between rounded px-6 py-3 text-primario-700 hover:bg-neutro-200"
              >
                {{ subtopic.name }} <span class="text-xs font-light italic text-gray-400">em breve</span>
              </a>
            </ng-template>
          </mat-panel-description>
        </mat-expansion-panel>

        <mat-divider class="my-1"></mat-divider>
      </mat-accordion>
    </div>
    <div class="mt-8 flex h-[1%] w-full items-center" *ngIf="isSidebarOpen">
      <div (click)="handleOpenVersion()" class="ml-2 cursor-pointer p-3 hover:bg-neutro-200">
        <h3 class="text-base text-gray-400">SPT ©</h3>
        <h3 class="text-base text-gray-400">Versão {{ version }}</h3>
      </div>
    </div>

    <ul [ngClass]="isSidebarOpen ? ' hidden' : 'block'" class="h-full">
      <div class="h-[80%]">
        <li
          class="my-4 flex cursor-pointer items-center justify-center rounded p-3 text-primario-700 transition-all duration-150 hover:bg-neutro-200"
          *ngFor="let option of routes"
          (click)="handleIconSidebar(option)"
          [title]="option.moduleName"
        >
          <span class="material-icons">{{ option.iconName }}</span>
        </li>
      </div>
      <div class="flex h-[20%] items-end pb-4">
        <h3 (click)="handleOpenVersion()" class="cursor-pointer p-3 text-base text-neutro-600 hover:bg-neutro-600 rounded-xl w-full">{{ version }}</h3>
      </div>
    </ul>
  </div>
</aside>
