import { Injectable } from '@angular/core';
import { SidebarModuleRoutingType, SidebarModulesName } from '@core/components';
import { EPermissions, ERoutes } from '@core/enums';
import { PermissionService } from '@core/services/permission.service';
import { compareStrings } from '@core/utils';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidebarService {
  isSidebarOpen = new BehaviorSubject<boolean>(false);

  //dashboard
  private isVisible_Dashboard = false;

  //Gestão da Produção
  private isVisible_GestaoProducaoCadastro = false;
  private isVisible_Campos = false;
  private isVisible_Cluster = false;

  private isVisible_GestaoProducao = false;

  //Administração
  private isVisible_AdministracaoCadastro = false;
  private isVisible_Cargos = false;
  private isVisible_Colaboradores = false;
  private isVisible_Empresas = false;
  private isVisible_Perfis = false;
  private isVisible_Usuarios = false;

  private isVisible_Administracao = false;

  constructor(private readonly permissionService: PermissionService) {}

  createSideBar(): SidebarModuleRoutingType[] {
    return [
      {
        id: 'dashboard',
        moduleName: SidebarModulesName.DASHBOARD,
        iconName: 'web',
        hasTopics: false,
        hasSubtopics: false,
        routeName: ERoutes.HOME,
        isVisible: this.isVisible_Dashboard
      },

      {
        id: 'gestao-producao',
        moduleName: SidebarModulesName.GESTAO_DA_PRODUCAO,
        iconName: 'gas_meter',
        hasTopics: false,
        hasSubtopics: true,
        isOpen: false,
        isVisible: this.isVisible_GestaoProducao,
        subtopics: [
          {
            id: 'gestao-producao-pts',
            name: 'PT',
            routeName: ERoutes.PT,
            isVisible: this.isVisible_GestaoProducao
          },
          {
            id: 'gestao-producao-aprs',
            name: 'APR',
            routeName: ERoutes.APR,
            isVisible: this.isVisible_GestaoProducao
          }
        ]
      },

      {
        id: 'administracao',
        moduleName: SidebarModulesName.ADMINISTRACAO,
        iconName: 'groups',
        hasTopics: true,
        hasSubtopics: false,
        isOpen: false,
        isVisible: this.isVisible_Administracao,
        topics: [
          {
            id: 'administracao-cadastro',
            moduleName: SidebarModulesName.CADASTRO,
            iconName: '',
            hasTopics: false,
            hasSubtopics: true,
            isOpen: false,
            isVisible: this.isVisible_AdministracaoCadastro,
            subtopics: [
              {
                id: 'administracao-cadastro-colaboradores',
                name: 'Colaboradores',
                routeName: ERoutes.COLABORADOR,
                isVisible: this.isVisible_Colaboradores
              },
              {
                id: 'administracao-cadastro-empresas',
                name: 'Empresas',
                routeName: ERoutes.EMPRESA,
                isVisible: this.isVisible_Empresas
              },
              {
                id: 'administracao-cadastro-perfis',
                name: 'Perfis',
                routeName: ERoutes.PERFIL,
                isVisible: this.isVisible_Perfis
              },
              {
                id: 'administracao-cadastro-usuarios',
                name: 'Usuários',
                routeName: ERoutes.USUARIO,
                isVisible: this.isVisible_Usuarios
              }
            ]
          }
        ],
        subtopics: []
      }
    ];
  }

  private sidebarRouting: SidebarModuleRoutingType[];

  getRoutes() {
    this.updatePermission();
    this.sidebarRouting = this.createSideBar();
    return this.sidebarRouting.filter((f) => f.isVisible);
  }

  hasAnyPermission(ePermission: EPermissions[]) {
    return this.permissionService.userHasPermissions(ePermission);
  }

  validateSearch(modules: SidebarModuleRoutingType[], textSearch: string) {
    return modules.filter((module) => {
      if (compareStrings(module.moduleName, textSearch)) {
        return module;
      }
      if (module.hasSubtopics && module.subtopics.filter((subtopic) => compareStrings(subtopic.name, textSearch)).length > 0) {
        module.isOpen = true;
        return module;
      }
      if (module.hasTopics) {
        if (
          module.topics.filter((topic) => {
            if (topic.hasSubtopics) {
              if (topic.subtopics.filter((subtopic) => compareStrings(subtopic.name, textSearch)).length > 0) {
                module.isOpen = true;
                topic.isOpen = true;
                return module;
              }
            }
          }).length > 0
        ) {
          module.isOpen = true;
          return module;
        }
        if (module.topics.filter((topic) => compareStrings(topic.moduleName, textSearch)).length > 0) {
          module.isOpen = true;
          return module;
        }
      }
    });
  }

  // prettier-ignore
  private updatePermission() {
    //dashboard
    this.isVisible_Dashboard = true;

    //Gestão da Produção > Cadastro
    this.isVisible_Campos = this.hasAnyPermission([EPermissions.ROLE_CADASTRO_CAMPO_LISTAR]);
    // this.isVisible_Cluster = this.hasAnyPermission([EPermissions.VISUALIZAR_CLUSTER]);

    this.isVisible_GestaoProducaoCadastro = this.isVisible_Campos || this.isVisible_Cluster;

    this.isVisible_GestaoProducao = this.isVisible_GestaoProducaoCadastro;


    //Administração > Cadastro
    this.isVisible_Empresas = this.hasAnyPermission([EPermissions.ROLE_CADASTRO_EMPRESA_LISTAR]);
    this.isVisible_Colaboradores = this.hasAnyPermission([EPermissions.ROLE_CADASTRO_PESSOA_LISTAR]);
    this.isVisible_Usuarios = this.hasAnyPermission([EPermissions.ROLE_CADASTRO_USUARIO_LISTAR]);
    this.isVisible_Cargos = this.hasAnyPermission([EPermissions.ROLE_CADASTRO_CARGO_LISTAR]);
    this.isVisible_Perfis = this.hasAnyPermission([EPermissions.ROLE_CADASTRO_PERFIL_LISTAR]);

    this.isVisible_AdministracaoCadastro =
      this.isVisible_Empresas || this.isVisible_Colaboradores || this.isVisible_Usuarios || this.isVisible_Cargos || this.isVisible_Perfis;

    this.isVisible_Administracao = this.isVisible_AdministracaoCadastro;
  }
}
