<div class="relative">
  <div *ngIf="environment" class="fixed top-0 left-0 right-0 z-[9999] flex w-full items-center justify-center bg-danger">
    <span class="text-xl font-semibold text-branco"> {{environment }}</span>
  </div>
  <div *ngIf="isLoggedUser" class="container mx-auto h-screen max-w-none">
    <app-sidebar></app-sidebar>
    <div class="flex h-full flex-row items-start justify-end transition-all duration-150">
      <app-navbar></app-navbar>
      <div
        class="h-full pt-20 {{ isSidebarOpen ? ''  : 'w-[calc(100%-6rem)]'}}"
        [ngClass]="isSidebarOpen ? 'xs:w-10/12 sm:w-8/12 md:w-8/12 w-9/12 xl:w-10/12 2xl:w-10/12 ' : ''"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoggedUser">
    <router-outlet></router-outlet>
  </div>
</div>
