import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ERoutes } from '@core/enums';
import { AuthService, MessageService, PermissionService } from '@core/services';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private readonly service: AuthService,
    private readonly router: Router,
    private readonly permissionService: PermissionService,
    private readonly messageService: MessageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const token = this.service.getToken();
    // const userHasPermission = this.permissionService.userHasPermissionRoute(state.url);
    const userHasPermission = true
    if (token && userHasPermission) {
      return true;
    } else if (token && !userHasPermission) {
      this.messageService.showAlert('Você não tem permissão para acessar essa rota');
      return this.router.createUrlTree([`${ERoutes.PATH_PRIVATE}`]);
    }
    return this.router.createUrlTree([`${ERoutes.PATH_AUTH}/${ERoutes.LOGIN}`]);
  }
}
