import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ERoutesApi } from '@core/enums';
import { CrudErrorService, CrudService } from '@core/services';
import { compareStrings } from '@core/utils';
import _ from 'lodash';
import { Filter } from '../input-filter';

@Component({
  selector: 'selector-checkbox-filter',
  templateUrl: 'selector-checkbox-filter.component.html'
})
export class SelectorCheckboxFilterComponent implements OnInit {
  @Input() route: string;
  @Input() attributeSelectorCheckbox: string;
  @Input() filtrosCache?: Map<string, Filter[]>;

  @Output() fieldToSearch? = new EventEmitter<Filter[]>();
  @Output() fieldToDelete? = new EventEmitter<string>();

  @ViewChild('searchInput') searchInput: ElementRef;

  isLoading = false;
  isFilterApplied: boolean;
  filterForm: FormGroup;
  searchControl = new FormControl();

  allValues: string[] = [];
  selectedValues: string[] = [];
  filteredValues: string[] = [];
  displayedValues: string[] = [];

  constructor(private readonly httpService: CrudService<string>, private readonly httpErrorService: CrudErrorService) {}

  ngOnInit() {
    this.handleLoadCache();
  }

  handleOpenFilter() {
    this.isLoading = true;

    this.httpService.getWithoutSlash(`${ERoutesApi.ITENS_PARA_FILTRO}/${this.route}`).subscribe({
      next: (value) => {
        this.allValues = value;
        this.filteredValues = value;
      },
      error: (err) => {
        this.isLoading = false;
        this.httpErrorService.handleErrorAPI(err, 'Erro ao listar dados, tente novamente');
      },
      complete: () => {
        this.isLoading = false;
        setTimeout(() => {
          this.searchInput.nativeElement.focus();
        }, 50);
      }
    });
  }

  setAll(checked: boolean) {
    if (checked) {
      this.selectedValues = _.union(this.selectedValues, this.filteredValues);
    } else {
      this.selectedValues = _.difference(this.selectedValues, this.filteredValues);
    }
  }

  setOne(checked: boolean, value: string) {
    if (checked) {
      this.selectedValues.push(value);
    } else {
      this.selectedValues = this.selectedValues.filter((v) => v !== value);
    }
  }

  hasFilterChanges() {
    return !(
      this.selectedValues.length === this.displayedValues.length &&
      this.displayedValues.every((display, index) => display === this.selectedValues[index])
    );
  }

  isCheckedAll() {
    return _.every(this.filteredValues, (value) => _.includes(this.selectedValues, value));
  }

  isCheckedOne(value: string) {
    return this.selectedValues.find((v) => v === value) !== undefined ? true : false;
  }

  handleSearch() {
    this.filteredValues = this.allValues.filter((value) => (compareStrings(value, this.searchControl.value) ? value : null));
  }

  handleLoadCache() {
    if (this.filtrosCache?.get(this.attributeSelectorCheckbox)) {
      const valuesCache = this.filtrosCache.get(this.attributeSelectorCheckbox)[0].value;
      this.selectedValues = valuesCache.split(',');
      this.displayedValues = valuesCache.split(',');
      this.isFilterApplied = valuesCache ? true : false;
    }
  }

  handleFilter() {
    const filter = this._getFilter();
    this.displayedValues = filter.value.split(',');
    this.fieldToSearch.emit([filter]);
  }

  handleCleanFilter() {
    this.selectedValues = [];
    this.displayedValues = [];
    const filter = this._getFilter();
    this.fieldToDelete.emit(filter.column);
  }

  private _getFilter(): Filter {
    const filter: Filter = {
      column: this.attributeSelectorCheckbox,
      value: this.selectedValues.join(',')
    };
    return filter;
  }
}
