<div mat-dialog-title class="mt-4 flex items-center justify-between">
  <h1 class="text-2xl font-bold">{{ data.title }}</h1>
  <button
    type="button"
    title="Fechar"
    class="flex items-center rounded p-1 transition-all duration-200 hover:bg-dark-blue-puple hover:text-white"
    (click)="dialogRef.close()"
  >
    <span class="material-icons text-black">close</span>
  </button>
</div>

<mat-spinner *ngIf="isLoading; else changeLogsTable"></mat-spinner>

<ng-template #changeLogsTable>
  <div mat-dialog-content class="overflow-y-auto">
    <table mat-table [dataSource]="changelogs" class="w-full" multiTemplateDataRows>
      <ng-container matColumnDef="acoes">
        <th mat-header-cell *matHeaderCellDef class="header-changelog bg-gray-100"></th>
        <td mat-cell *matCellDef="let element" class="overflow-x-auto">
          <button
            type="button"
            [title]="element.expanded ? 'Fechar' : 'Expandir'"
            class="button-icon text-dark-blue-puple"
            (click)="element.expanded = !element.expanded; $event.stopPropagation()"
          >
            <span *ngIf="!element.expanded" class="material-icons text-xl">keyboard_arrow_down</span>
            <span *ngIf="element.expanded" class="material-icons text-xl">keyboard_arrow_up</span>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="versao">
        <th mat-header-cell *matHeaderCellDef class="header-changelog bg-gray-100 text-center text-base font-bold">Versão</th>
        <td mat-cell *matCellDef="let element" class="text-center">{{ element.versao }}</td>
      </ng-container>

      <ng-container matColumnDef="data">
        <th mat-header-cell *matHeaderCellDef class="header-changelog bg-gray-100 text-center text-base font-bold">Data</th>
        <td mat-cell *matCellDef="let element" class="text-center">{{ element.data | date : 'dd/MM/yyyy' }}</td>
      </ng-container>

      <ng-container matColumnDef="resumo">
        <th mat-header-cell *matHeaderCellDef class="header-changelog bg-gray-100 text-center text-base font-bold">Resumo</th>
        <td mat-cell *matCellDef="let element" class="text-center">{{ element.resumo }}</td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="mt-4 max-h-96 overflow-auto bg-white px-4 transition-all duration-150" *ngIf="element.expanded">
            <ul>
              <li style="list-style-type: disc" class="ml-4 mb-4" *ngFor="let change of element.changes">{{ change.change }}</li>
            </ul>
            <h3 class="ml-2 text-base" *ngIf="element.changes.length === 0">Nenhuma mudança nessa versão</h3>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        (click)="element.expanded = !element.expanded"
        class="expanded-row transition-all duration-150 hover:bg-slate-100"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="h-0"></tr>
    </table>
  </div>
</ng-template>
