import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ERoutes, ERoutesApi } from '@core/enums';
import { User } from '@core/models';
import { subMinutes } from 'date-fns';
import { environment } from 'environments/environment';
import jwt_decode from 'jwt-decode';
import messages from 'locale/messages.pt';
import { BehaviorSubject, Observable, catchError } from 'rxjs';
import { CrudErrorService, MessageService } from '.';

const USUARIO_LOGADO = 'USUARIO_LOGADO';
const TOKEN = 'token@spt';
const FILTROS_TABELAS = 'FILTROS_TABELAS';
const REFRESH_TOKEN = 'refresh_token';
const SIDEBAR_CONFIG = 'sidebarConfig@spt';

type JWTToken = {
  refresh: string;
  exp: number;
  iat: number;
  jti: string;
  user_id: number;
};

type SignInResponse = {
  token: string;
  detalhesUsuario: User;
  sessaoOcupada?: boolean;
  dataDeCriacaoDoToken: string;
  dataAtualDoSistema: string;
  dataDeExpiracaoDoToken: string;
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly _baseUrl = `${environment.apiUrl}/${ERoutesApi.AUTH}/`;
  readonly _token = new BehaviorSubject<string>(null);

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly messageService: MessageService,
    private readonly httpErrorService: CrudErrorService
  ) {
    this._token.next(localStorage.getItem('token@spt'));
  }

  signIn(login: string, senha: string): Observable<SignInResponse> {
    return this.http.post<SignInResponse>(`${this._baseUrl}login`, { login, senha }, { headers: this.generateHeader() }).pipe(
      catchError((err) => {
        this.httpErrorService.handleErrorAPI(err, 'Erro ao fazer login');
        throw err;
      })
    );
  }

  handleSetUsuario(user: User) {
    localStorage.setItem(USUARIO_LOGADO, JSON.stringify(user));
  }

  handleConfirmationCode(login: string, password: string, codigo_login: string): Observable<SignInResponse> {
    return this.http.post<any>(this._baseUrl, { username: login, password, codigo_login }).pipe(
      catchError((err) => {
        this.httpErrorService.handleErrorAPI(err, 'Erro ao fazer login');
        throw err;
      })
    );
  }

  handleUserAuthenticate(input: SignInResponse) {
    this._token.next(input.token);
    this.handleSetTokens(input.token, input.token);
    this.handleSetUsuario(input.detalhesUsuario);
  }

  handleSetTokens(accessToken: string, refreshToken: string) {
    localStorage.setItem(TOKEN, accessToken);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  getToken() {
    const storagedToken = localStorage.getItem(TOKEN);
    this._token.next(storagedToken);
    return storagedToken;
  }

  verifyToken() {
    try {
      const storagedToken = localStorage.getItem(TOKEN);
      const tokenDecoded = jwt_decode<JWTToken>(storagedToken);
      const expiresIn = tokenDecoded.exp * 1000;
      const timeToRefresh = subMinutes(expiresIn, 30).getTime();
      const now = new Date().getTime();
      if (now > timeToRefresh) {
        const refreshToken = localStorage.getItem(REFRESH_TOKEN);
        this.http
          .post<{ access: string }>(`${environment.apiUrl}/${ERoutesApi.TOKEN_REFRESH}/`, {
            refresh: refreshToken
          })
          .subscribe((value) => {
            if (localStorage.getItem(TOKEN)) {
              localStorage.setItem(TOKEN, value.access);
              this._token.next(value.access);
            }
          });
      }
    } catch (error) {
      if (this._token.value) {
        this.messageService.showAlert(messages['http.status.401.message'], messages['http.status.401.title']);
        this.signOut();
      }
      return;
    }
  }

  signOut() {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(USUARIO_LOGADO);
    localStorage.removeItem(FILTROS_TABELAS);
    localStorage.removeItem(SIDEBAR_CONFIG);
    this._token.next(null);
    this.router.navigate([`${ERoutes.PATH_AUTH}/${ERoutes.LOGIN}`]);
  }

  loggedUser(): User {
    const userLocalStorage = localStorage.getItem(USUARIO_LOGADO);
    return userLocalStorage !== null ? JSON.parse(userLocalStorage) : null;
  }

  isLoggedUser(): boolean {
    const userLocalStorage = localStorage.getItem(USUARIO_LOGADO);
    return userLocalStorage !== null ? true : false;
  }

  private generateHeader(customHeaders?: HttpHeaders): any {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Origem': 'WEB',
      Accept: 'application/json',
      ...customHeaders
    });

    return headers;
  }
}
