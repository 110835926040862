import { Injectable } from '@angular/core';
import { ERoutesApi } from '@core/enums';
import { GenericCrudService } from '@core/services';
import { SelecterOption } from '@core/types';
import { CargoService } from '@features/cargos/services';
import { EmpresaService } from '@features/empresas/services';
import { Observable, forkJoin, map, of } from 'rxjs';
import { ColaboradorFormFetch, IColaboradorService } from '../adapters';
import { Colaborador, ColaboradorAPI, TipoColaborador } from '../model';

@Injectable({ providedIn: 'root' })
export class ColaboradorService extends GenericCrudService<ColaboradorAPI, Colaborador> implements IColaboradorService {
  constructor(private readonly cargoHttpService: CargoService, private readonly empresaHttpService: EmpresaService) {
    super(ERoutesApi.COLABORADORES);
  }

  handleFetchTipoColaboradorLikeSelecterOptions(): Observable<SelecterOption[]> {
    return this.httpService
      .get(`${ERoutesApi.COLABORADORES}/tipos`)
      .pipe(map<TipoColaborador[], SelecterOption[]>((values) => values.map((value) => ({ label: value.nome, value: value.id }))));
  }

  handleFetchFormInformation(id?: string | number): Observable<ColaboradorFormFetch> {
    return forkJoin([
      this.empresaHttpService.handleFetchLikeSelecterOptions(),
      this.cargoHttpService.handleFetchLikeSelecterOptions(),
      this.handleFetchTipoColaboradorLikeSelecterOptions(),
      id ? this.handleFetchById(id) : of(undefined)
    ]).pipe(
      map<any[], ColaboradorFormFetch>((values) => ({
        empresasOptions: values[0],
        cargosOptions: values[1],
        tiposColaboradorOptions: values[2],
        colaborador: values[3]
      }))
    );
  }

  handleFetchOperadoresLikeSelecterOptions(): Observable<SelecterOption[]> {
    return this.httpService
      .getWithoutSlash(`${ERoutesApi.COLABORADORES}/?tipo=OPERADOR`)
      .pipe(map<ColaboradorAPI[], SelecterOption[]>((values) => values.map((value) => ({ label: value.nome, value: value.id }))));
  }

  handleFetchTecnicosLikeSelecterOptions(): Observable<SelecterOption[]> {
    return this.httpService
      .getWithoutSlash(`${ERoutesApi.COLABORADORES}/?tipo=TECNICO`)
      .pipe(map<ColaboradorAPI[], SelecterOption[]>((values) => values.map((value) => ({ label: value.nome, value: value.id }))));
  }
}
