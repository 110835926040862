<div class="flex flex-row items-center justify-center">
  <button [matMenuTriggerFor]="menu" (click)="handleOpenFilter()">
    <span *ngIf="!isFilterApplied" ngClass="material-icons text-dark-blue-puple" title="Abrir filtro">filter_list</span>
  </button>

  <div *ngIf="isFilterApplied" class="flex flex-row items-center justify-center gap-2">
    <button [matMenuTriggerFor]="menu" (click)="handleOpenFilter()">
      <mat-chip class="flex items-center">{{ displayedValues.length }} selecionados</mat-chip>
    </button>
    <button (click)="handleCleanFilter()">
      <span title="Remover Filtro" class="material-icons text-dark-blue-puple hover:text-danger">close</span>
    </button>
  </div>
</div>

<mat-menu #menu>
  <ng-template matMenuContent>
    <div (click)="$event.stopPropagation()" class="w-full min-w-[17rem]">
      <section class="flex items-center gap-2 p-2">
        <span class="material-icons text-dark-blue-puple">filter_alt</span>
        <label>Filtro</label>
      </section>
      <hr />

      <section>
        <div *ngIf="isLoading; else values">
          <div class="flex w-full items-center justify-center">
            <mat-spinner class="m-0 w-12"></mat-spinner>
          </div>
        </div>

        <ng-template #values>
          <section class="p-2">
            <input
              #searchInput
              id="search-options"
              name="search-options"
              placeholder="Digite para pesquisar"
              title="Digite para pesquisar"
              class="w-full rounded border-2 border-gray-100 py-2 px-4 text-dark-blue-puple outline-gray-300"
              [formControl]="searchControl"
              (ngModelChange)="handleSearch()"
              (focus)="(true)"
              autocomplete="off"
              (keydown.enter)="$event.preventDefault()"
            />
          </section>

          <ul class="flex flex-col justify-between">
            <p *ngIf="filteredValues.length === 0" class="ml-3 mb-2 text-base">Nenhum dado encontrado</p>
            <li *ngIf="filteredValues.length > 0">
              <mat-checkbox color="primary" [checked]="isCheckedAll()" (change)="setAll($event.checked)">Selecionar Todos</mat-checkbox>
              <mat-divider></mat-divider>

              <ul class="my-4 max-h-44 w-full grid-cols-2 overflow-y-auto scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
                <li *ngFor="let value of filteredValues">
                  <mat-checkbox
                    [id]="value"
                    color="primary"
                    class="tex-sm"
                    [checked]="isCheckedOne(value)"
                    (change)="setOne($event.checked, value)"
                  >
                    {{ value }}
                  </mat-checkbox>
                </li>
              </ul>
            </li>
          </ul>
        </ng-template>
      </section>

      <section class="flex items-center justify-end gap-x-2 px-2">
        <span class="text-sm" *ngIf="hasFilterChanges()">Existem alterações não aplicadas</span>
        <button
          title="Pesquisar"
          class="base-btn text-white"
          (click)="handleFilter()"
          [disabled]="selectedValues.length === 0"
          [ngClass]="selectedValues.length === 0 ? 'bg-gray-500' : 'bg-dark-blue-puple'"
        >
          <span class="material-icons">search</span>
        </button>
      </section>
    </div>
  </ng-template>
</mat-menu>
