const upperCaseList: string[] = ['SPM'];
const defaultBlacklist: string[] = ['de', 'do', 'da', 'das', 'dos', 'com', 'kg', 'g', 'l', 'c/', 'e'];

export const capitalizeAll = (nome: string): string => {
  const parts = nome.trim().split(' ');
  const resultado = parts
    .map((part) => {
      part = part.toLowerCase();

      if (upperCaseList.includes(part)) {
        return part.toUpperCase();
      }

      if (!defaultBlacklist.includes(part)) {
        return capitalize(part);
      }

      return part.toLowerCase();
    })
    .join(' ');

  return resultado;
};

export function capitalize(str: string): string {
  const lower = str.toLowerCase();
  return lower.charAt(0).toUpperCase() + lower.slice(1);
}

export function removeAccents(string: string) {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function compareStrings(string1: string | number, string2: string | number) {
  return removeAccents(string1.toString()).toLowerCase().includes(removeAccents(string2.toString()).toLowerCase());
}
