import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { ERoutesApi } from '@core/enums';
import { ICrudService } from '@core/interfaces';
import { PaginateParams, PaginateResponse, RequestBody } from '@core/types';
import { SelecterOption } from '@shared/forms';
import { map, Observable } from 'rxjs';
import { CrudService } from './crud.service';
import {QuestionarioPtCompletoDTO} from "@features/pt/dtos";
import {Pessoa} from "@features/pessoa/model";
import {_Apr} from "@features/apr/model";

class BaseApiModel {
  id?: string | number;
  nome?: string;
  display?: string;
}

@Injectable({ providedIn: 'root' })
export class GenericCrudService<T extends BaseApiModel, BasicModel> implements ICrudService<T, BasicModel> {
  protected readonly httpService = inject(CrudService<any>);
  protected readonly location = inject(Location);
  constructor(private readonly resource: ERoutesApi) {}

  handleFetchById(id: string | number): Observable<T> {
    return this.httpService.getOne(`${this.resource}/${id}`);
  }

  handleFetchPaginate(params: PaginateParams): Observable<PaginateResponse<T>> {
    const { limit, offset, order, query, filters } = params;
    return this.httpService.getWithPaginate(`${this.resource}/${filters}limit=${limit}&offset=${offset}&ordem=${order}&query=${query}`);
  }

  handleFetchGetDataPt(rota : string, idResposta: number): Observable<QuestionarioPtCompletoDTO> {
    return this.httpService.getDadosQuestionario(`${this.resource}/${rota}/${idResposta}`);
  }

  handleFetchGetApr(idResposta: number): Observable<_Apr> {
    return this.httpService.getApr(`${this.resource}/${idResposta}`);
  }

  handleFetchGetPessoaById(id: number): Observable<Pessoa> {
    return this.httpService.getPessoa(`${this.resource}/${id}`);
  }

  handleFetchWithoutPaginate(params: PaginateParams): Observable<T[]> {
    const { order, query, filters } = params;
    return this.httpService.getWithoutSlash(`${this.resource}/${filters}ordem=${order}&query=${query}`);
  }

  handleFetchOneWithoutPaginate(params: PaginateParams): Observable<T> {
    const { order, query, filters } = params;
    return this.httpService.getOneWithoutSlash(`${this.resource}/${filters}ordem=${order}&query=${query}`);
  }

  handlePostOneWithoutPaginate(params: PaginateParams, body?: RequestBody): Observable<T> {
    const { order, query, filters } = params;

    const formBody: RequestBody = body ?? {};
    formBody['ordem'] = order;

    if (filters) {
      Object.entries(filters).forEach((atributte: any) => {
        const operador = atributte[1]['operator'] ?? '';
        formBody[atributte[1]['column'] + operador] = atributte[1]['value'];
      });
    }

    return this.httpService.postWithoutSlash(`${this.resource}/filtrar/?query=${query}`, formBody);
  }

  handlePostPaginate(params: PaginateParams, body?: RequestBody): Observable<PaginateResponse<T>> {
    const { limit, offset, order, query, filters } = params;

    const formBody: RequestBody = body ?? {};
    formBody['ordem'] = order;

    if (filters) {
      Object.entries(filters).forEach((atributte: any) => {
        const operador = atributte[1]['operator'] ?? '';
        formBody[atributte[1]['column'] + operador] = atributte[1]['value'];
      });
    }

    return this.httpService.postWithoutSlash(`${this.resource}/filtrar/?limit=${limit}&offset=${offset}&query=${query}`, formBody);
  }

  handleSave(model: Partial<BasicModel>): Observable<T> {
    return this.httpService.post(`${this.resource}`, model);
  }

  handleCreateImport(model: Partial<BasicModel>): Observable<T> {
    console.log("this.resource", this.resource)
    // return null;
    return this.httpService.post(`${this.resource}`, model);
  }

  handleSaveWithFile(model: FormData): Observable<T> {
    return this.httpService.postWithFile(`${this.resource}`, model);
  }

  handleUpdate(model: Partial<BasicModel>, id?: string | number): Observable<T> {
    return this.httpService.put(`${this.resource}/${id}`, model);
  }

  handlePatch(model: Partial<BasicModel>, id?: string | number): Observable<T> {
    return this.httpService.patch(`${this.resource}/${id}`, model);
  }

  handleUpdateWithFile(model: FormData, id?: string | number): Observable<T> {
    return this.httpService.putWithFile(`${this.resource}/${id}`, model);
  }

  handleFetchLikeSelecterOptions(): Observable<SelecterOption[]> {
    return this.httpService
      .get(`${this.resource}`)
      .pipe(map<T[], SelecterOption[]>((values: T[]) => values.map((value) => ({ label: value.display || value.nome, value: value.id }))));
  }

  handleDelete(id: string | number): Observable<void> {
    return this.httpService.delete(`${this.resource}/${id}`).pipe(
      map((value) => {
        return;
      })
    );
  }

  handleBack(path?: string) {
    return path ? this.location.go(path) : this.location.back();
  }
}
