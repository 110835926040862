import { Component, DoCheck, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangelogModalComponent, SidebarConfiguration, SidebarModuleRoutingType, SidebarModulesName } from '@core/components';
import { AuthService, ChangelogService, CrudErrorService, LocalStorageService, SidebarService } from '@core/services';
import { FilterService } from '@core/services/filter.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements DoCheck, OnInit {
  isSidebarOpen = false;
  sidebarConfig: SidebarConfiguration;
  version = '';

  selectedModule: SidebarModulesName = null;
  selectedSubModule: SidebarModulesName = null;
  selectedSubModuleInside: SidebarModulesName = null;

  isLoggedUser = false;
  isExpandMenu = false;

  routes: SidebarModuleRoutingType[] = [];
  filteredRoutes: SidebarModuleRoutingType[] = [];
  textSearch = '';

  constructor(
    private readonly sidebarService: SidebarService,
    private readonly localStorageService: LocalStorageService,
    private readonly filtroService: FilterService,
    private readonly authService: AuthService,
    private readonly changelogService: ChangelogService,
    private readonly httpErrorService: CrudErrorService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.routes = this.sidebarService.getRoutes();
    this.filteredRoutes = this.sidebarService.getRoutes();
    this.handleGetVersion();

    if (this.sidebarConfig) {
      this.selectedModule = this.sidebarConfig.lastTabOpen;
    } else {
      this.localStorageService.saveSidebarConfig({ isOpen: this.isSidebarOpen, lastTabOpen: this.selectedModule });
    }

    this.sidebarConfig = this.localStorageService.getSidebarConfig();
    this.sidebarService.isSidebarOpen.next(this.sidebarConfig.isOpen);
    this.isSidebarOpen = this.sidebarService.isSidebarOpen.getValue();
  }

  ngDoCheck() {
    this.isLoggedUser = this.authService.isLoggedUser();
    if (this.sidebarConfig) {
      this.isSidebarOpen = this.sidebarService.isSidebarOpen.getValue();
      this.sidebarConfig.isOpen = this.isSidebarOpen;
      this.localStorageService.saveSidebarConfig(this.sidebarConfig);
    }

    if (this.textSearch) {
      this.filteredRoutes = this.sidebarService.validateSearch(this.routes, this.textSearch);
    } else {
      this.filteredRoutes = this.routes;
    }
  }

  handleSidebar() {
    this.sidebarService.isSidebarOpen.next(!this.isSidebarOpen);
  }

  handleIconSidebar(option: SidebarModuleRoutingType) {
    if (option.hasTopics || option.hasSubtopics) {
      this.handleClickModule(option.moduleName);
    } else {
      this.handleClickModule(null);
    }
    this.handleSidebar();
  }

  handleClickModule(moduleName: SidebarModulesName) {
    if (this.selectedModule === moduleName) {
      this.selectedModule = null;
    } else {
      this.selectedModule = moduleName;
    }

    this.selectedSubModule = null;

    this.sidebarConfig.lastTabOpen = this.selectedModule;
    this.localStorageService.saveSidebarConfig(this.sidebarConfig);
  }

  handleClickSubModule(moduleName: SidebarModulesName) {
    if (this.selectedSubModule === moduleName) {
      this.selectedSubModule = null;
    } else {
      this.selectedSubModule = moduleName;
    }
  }

  handleClickSubModuleInside(moduleName: SidebarModulesName) {
    if (this.selectedSubModuleInside === moduleName) {
      this.selectedSubModuleInside = null;
    } else {
      this.selectedSubModuleInside = moduleName;
    }
  }

  clickedOutside() {
    if (this.isSidebarOpen) {
      this.isSidebarOpen = false;
      this.sidebarService.isSidebarOpen.next(false);
      this.sidebarConfig.isOpen = false;
    }
  }

  mouseHoverInside(tipoEvent : string) {
    if (tipoEvent == 'in'){
      this.isSidebarOpen = true;
      this.sidebarService.isSidebarOpen.next(true);
      this.sidebarConfig.isOpen = true;
    }

    if (tipoEvent == 'out'){
      this.isSidebarOpen = false;
      this.sidebarService.isSidebarOpen.next(false);
      this.sidebarConfig.isOpen = false;
    }
  }

  handleExpandMenu() {
    this.isExpandMenu = !this.isExpandMenu;
  }

  cleanFilter(rota: string) {
    this.filtroService.removeFiltro(rota);
  }

  handleGetVersion() {
    this.version = '0.1';
    // TODO: Implementar quando a API tiver essa funcionalidade
    // this.changelogService.handleFetchVersion().subscribe({
    //   next: (data) => {
    //     this.version = data;
    //   },
    //   error: (err) => {
    //     this.httpErrorService.handleErrorAPI(err, 'Erro ao bucar versão atual do sistema');
    //   }
    // });
  }

  handleOpenVersion() {
    this.dialog.open(ChangelogModalComponent, {
      width: '60%',
      height: '80%',
      data: { title: 'Versões Lançadas' }
    });
  }
}
