export enum SidebarModulesName {
  DASHBOARD = 'Dashboard',
  GESTAO_DA_PRODUCAO = 'PT e APR',
  RESERVATORIO = 'Reservatório',
  EQUIPAMENTO = 'Equipamento',
  FECHAMENTO_PRODUCAO = 'Fechamento de Produção',
  LABORATORIO = 'Laboratório',
  ADMINISTRACAO = 'Administração',
  CADASTRO = 'Cadastros',
  GESTAO_DE_FLUIDOS = 'Gestão de Fluidos',
  MEDICAO = 'Medições',
  FISCAL = 'Fiscais',
  EQUIPAMENTO_LABORATORIO = 'Laboratório',
  EQUIPAMENTO_SUPERFICIE = 'Superfície',
  INSTRUMENTOS = 'Instrumentos',
  INJECOES = 'Injeções',
  INJECOES_DIARIAS = 'Injeções Diárias',
  RELATORIOS = 'Relatórios',
  MEDICAO_FISCAL = 'Medição Fiscal',
  GESTAO_DE_LACRES = 'Gestão de Lacres',
  GESTAO_DE_PRAZOS = 'Gestão de Prazos'
}
