export enum ERoutesApi {
  AUTH = 'auth',
  PT = 'pt',
  APR = 'apr',
  PESSOA = 'colaboradores',
  TOKEN_REFRESH = 'token_refresh',
  RECUPERAR_SENHA = 'recuperar_senha',
  RECUPERAR_SENHA_CODIGO = 'recuperar_senha_confirmar_codigo',
  ALTERAR_SENHA = 'alterar_senha',
  ALTERAR_ESTADO = 'alterar_estado',
  CLUSTERS = 'clusters',
  EMPRESAS = 'empresas',
  PERFIS = 'perfis',
  PERMISSOES = 'permissoes',
  CARGOS = 'cargos',
  CAMPOS = 'campos',
  CAMPOS_SIGLA = 'campos_sigla',
  COLABORADORES = 'colaboradores',
  TECNICOS = 'tecnicos',
  USUARIOS = 'usuarios',
  EXPORT_CSV = 'exportar_csv',
  EXPORT_CSV_RESUMO = 'exportar_csv_resumo',
  AMBIENTE = 'ambiente',
  ITENS_PARA_FILTRO = 'itens-para-filtro',
  CHANGELOG = 'changelog',
  PROCESSAR = 'processar',
  RELATORIO = 'relatorio'
}
