<div class="flex flex-row items-center justify-center">
  <button [matMenuTriggerFor]="menu" (click)="handleOpenFilter()">
    <span *ngIf="!isFilterApplied" ngClass="material-icons text-dark-blue-puple" title="Abrir filtro">filter_list</span>
  </button>

  <div *ngIf="isFilterApplied" class="flex flex-row items-center justify-center gap-2">
    <button [matMenuTriggerFor]="menu" (click)="handleOpenFilter()">
      <mat-chip class="flex items-center" *ngIf="valueType === 'NUMBER'">
        {{ valueOperator === 'a' ? otherValue + ' a ' + valueFilter : (valueOperator ?? '') + ' ' + valueFilter }}
      </mat-chip>

      <mat-chip class="flex items-center" *ngIf="valueType === 'DATE'">
        {{
          valueOperator === 'a'
            ? (otherValue | date : 'dd/MM/yyyy') + ' a ' + (valueFilter | date : 'dd/MM/yyyy')
            : (valueOperator ?? '') + ' ' + (valueFilter | date : 'dd/MM/yyyy')
        }}
      </mat-chip>

      <mat-chip class="flex items-center" *ngIf="valueType === 'DATE_TIME'">
        {{
          valueOperator === 'a'
            ? (otherValue | date : 'dd/MM/yyyy HH:mm' : 'GMT-3') + ' a ' + (valueFilter | date : 'dd/MM/yyyy HH:mm' : 'GMT-3')
            : (valueOperator ?? '') + ' ' + (valueFilter | date : 'dd/MM/yyyy HH:mm' : 'GMT-3')
        }}
      </mat-chip>

      <mat-chip class="flex items-center" *ngIf="valueType === 'DATE_TIME_MILLISECONDS'">
        {{
          valueOperator === 'a'
            ? (otherValue.replace(',', '.') | date : 'dd/MM/yyyy HH:mm:ss' : 'GMT-3') +
              ' a ' +
              (valueFilter.replace(',', '.') | date : 'dd/MM/yyyy HH:mm:ss' : 'GMT-3')
            : (valueOperator ?? '') + ' ' + (valueFilter.replace(',', '.') | date : 'dd/MM/yyyy HH:mm:ss' : 'GMT-3')
        }}
      </mat-chip>

      <mat-chip class="flex items-center" *ngIf="!valueType"> {{ valueFilter }}</mat-chip>
    </button>
    <button (click)="handleCleanFilter()">
      <span title="Remover Filtro" class="material-icons text-dark-blue-puple hover:text-danger">close</span>
    </button>
  </div>
</div>

<mat-menu #menu>
  <ng-template matMenuContent>
    <div (click)="$event.stopPropagation()">
      <section class="flex items-center gap-2 p-2">
        <span class="material-icons text-dark-blue-puple">filter_alt</span>
        <label>Filtro</label>
      </section>
      <hr />

      <form [formGroup]="filterForm" (ngSubmit)="handleFilter()">
        <section>
          <div *ngIf="valueType === 'NUMBER'" class="flex">
            <mat-radio-group formControlName="operator" aria-labelledby="radio-group-label" class="input-radio-group p-1">
              <mat-radio-button color="primary" class="input-radio-button" value="">Igual</mat-radio-button>
              <mat-radio-button color="primary" class="input-radio-button" value="__menor_igual">Menor e Igual</mat-radio-button>
              <mat-radio-button color="primary" class="input-radio-button" value="__maior_igual">Maior e Igual</mat-radio-button>
            </mat-radio-group>
          </div>

          <div
            *ngIf="valueType === 'DATE_TIME' || valueType === 'DATE_TIME_MILLISECONDS' || valueType === 'DATE' || valueType === 'TIME'"
            class="flex"
          >
            <mat-radio-group formControlName="operator" aria-labelledby="radio-group-label" class="input-radio-group p-1">
              <mat-radio-button color="primary" class="input-radio-button" value="">É igual a</mat-radio-button>
              <mat-radio-button color="primary" class="input-radio-button" value="__menor_igual">Antes de</mat-radio-button>
              <mat-radio-button color="primary" class="input-radio-button" value="__maior_igual">Depois de</mat-radio-button>
              <mat-radio-button color="primary" class="input-radio-button" value="entre">Está entre</mat-radio-button>
            </mat-radio-group>
          </div>
          <hr />

          <div class="flex w-full flex-col gap-2 p-2">
            <div
              class="grid w-full grid-cols-8 content-center items-center justify-center gap-x-2"
              *ngIf="filterForm.get('operator').value === 'entre'"
            >
              <input
                *ngIf="valueType !== 'NUMBER'; else INPUT_NUMBER"
                [type]="inputType"
                [step]="valueType === 'DATE_TIME_MILLISECONDS' ? '0.001' : ''"
                placeholder="Digite aqui"
                class="col-span-6 w-full rounded border-2 border-gray-100 py-2 px-4 text-dark-blue-puple outline-gray-300"
                formControlName="otherValue"
              />
              <div class="col-span-2 text-center">
                <span>e</span>
              </div>
            </div>

            <div class="grid w-full grid-cols-8 gap-x-2">
              <input
                #searchInput
                *ngIf="valueType !== 'NUMBER'; else INPUT_NUMBER"
                [type]="inputType"
                [step]="valueType === 'DATE_TIME_MILLISECONDS' ? '0.001' : ''"
                placeholder="Digite aqui"
                class="col-span-6 w-full rounded border-2 border-gray-100 py-2 px-4 text-dark-blue-puple outline-gray-300"
                formControlName="value"
              />
              <ng-template #INPUT_NUMBER>
                <input
                  #searchInput
                  [type]="'text'"
                  [mask]="'0*,0*'"
                  [dropSpecialCharacters]="false"
                  class="col-span-6 w-full rounded border-2 border-gray-100 py-2 px-4 text-dark-blue-puple outline-gray-300"
                  formControlName="value"
                  placeholder="Digite aqui"
                />
              </ng-template>

              <button
                title="Pesquisar"
                class="base-btn col-span-2 text-white"
                [disabled]="!filterForm.value.value"
                [ngClass]="!filterForm.value.value ? 'bg-gray-500' : 'bg-dark-blue-puple'"
              >
                <span class="material-icons">search</span>
              </button>
            </div>
            <div>
              <span class="text-sm" *ngIf="hasFilterChanges()">Existem alterações não aplicadas</span>
            </div>
          </div>
        </section>
      </form>
    </div>
  </ng-template>
</mat-menu>
