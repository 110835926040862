<fieldset [disabled]="isReadonly" class="relative my-4 w-full disabled:bg-transparent">
  <div class="input-wrapper disabled:bg-transparent" [ngClass]="isReadonly ? 'border-gray-300' : 'border-gray-700'">
    <input
      [type]="type"
      [id]="id"
      [name]="id"
      [(ngModel)]="value"
      [placeholder]="label"
      [step]="step"
      class="input-base peer text-dark-blue-puple disabled:bg-transparent"
      [mask]="mask"
      [dropSpecialCharacters]="false"
      (ngModelChange)="_handleChange()"
      autocomplete="off"
      [max]="checkMax()"
      (blur)="_onBlur()"
      (keypress)="_onKeyPress()"
    />
    <label [attr.for]="id" class="float-label-input" [ngClass]="isReadonly ? 'text-gray-300' : ''">{{ label }}</label>
  </div>
  <div>
    <span class="span-required" *ngIf="control.hasError('required') && submitted">* Campo obrigatório</span>
    <span class="span-required" *ngIf="control.hasError('invalidDate') && submitted">Data inválida</span>
    <span class="span-required" *ngIf="control.hasError('email') && submitted">Precisa ser informado um email válido</span>
    <span class="span-required" *ngIf="(control.hasError('maxlength') && submitted) || (control.hasError('minlength') && submitted)"
      >Tamanho Inválido</span
    >
  </div>
</fieldset>
