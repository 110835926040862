<nav
  class="fixed left-0 top-0 z-[9998] flex h-[6.5rem] w-full flex-col items-center justify-center bg-branco transition-all duration-150"
  *ngIf="isLoggedUser"
>
  <div class="flex h-full w-full flex-row items-center justify-end px-6">
    <button
      class="relative rounded bg-primario-700 py-1 pl-1"
      (click)="handleOpenOptions()"
      clickOutside
      (clickOutside)="clickedOutside()"
    >
      <div class="flex items-center justify-center gap-2 px-2">
        <span class="font-medium text-branco">Olá, {{ user.nome ? user.nome : 'Usuário' }}</span>
        <button
          [title]="isOpenOptions ? 'Fechar' : 'Expandir'"
          type="button"
          class="flex items-center justify-center rounded-full p-1 text-branco"
        >
          <span class="material-icons transition-all duration-300" [ngClass]="isOpenOptions ? 'rotate-180' : 'rotate-0'">expand_more</span>
        </button>
      </div>
      <ul class="absolute left-0 w-full rounded-b bg-primario-500 text-branco shadow-md" [ngClass]="isOpenOptions ? 'block' : 'hidden'">
<!--        <li-->

<!--          (click)="handleNavigateToUserProfile()"-->
<!--          title="Perfil"-->
<!--          class="flex cursor-pointer items-center justify-start gap-2 p-2 font-light transition-all duration-150 hover:bg-neutro-700"-->
<!--        >-->
<!--          <span class="material-icons">person</span> Perfil-->
<!--        </li>-->
        <li
          routerLink="alterar-senha"
          class="flex cursor-pointer items-center justify-start gap-2 p-2 font-light transition-all duration-150 hover:bg-primario-900 hover:text-branco"
        >
          <span class="material-icons">sync</span> Alterar senha
        </li>
        <li
          (click)="logout()"
          title="Sair"
          class="flex cursor-pointer items-center justify-start gap-2 p-2 font-light transition-all duration-150 hover:bg-primario-900 hover:text-branco"
        >
          <span class="material-icons">power_settings_new</span> Logout
        </li>
      </ul>
    </button>
  </div>
  <hr class="border" [ngClass]="isSidebarOpen ? 'hrOpen' : 'hrClose'" />
</nav>
