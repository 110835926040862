import { environment } from '../environments/environment';

export class AppSettings {
  public static API_ENDPOINT = `${AppSettings.getHostAddress()}`;

  static getHostAddress(): string {
    if (environment.apiUrl) {
      const url = `${environment.apiUrl}`;
      if (environment.production) {
        return `${url}`;
      } else {
        return `${url}`;
      }
    } else {
      const { protocol, host } = window.location;
      return `${host}`;
    }
  }
}
