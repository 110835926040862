import { Injectable } from '@angular/core';
import { FilterTable } from '@core/types/filter-table.type';

const FILTROS_TABELAS = 'FILTROS_TABELAS';

@Injectable({ providedIn: 'root' })
export class FilterService {
  private saveFiltro(rota: string, filtro: FilterTable) {
    let mapFilter = JSON.parse(localStorage.getItem(FILTROS_TABELAS), this._reviver);
    if (mapFilter == null) {
      mapFilter = new Map<string, FilterTable>();
    }
    mapFilter.set(rota, filtro);
    localStorage.setItem(FILTROS_TABELAS, JSON.stringify(mapFilter, this._replacer));
  }

  private getFiltro(rota: string): FilterTable {
    const mapFilter = JSON.parse(localStorage.getItem(FILTROS_TABELAS), this._reviver);
    return mapFilter !== null ? mapFilter.get(rota) : null;
  }

  removeFiltro(rota: string) {
    const mapFilter = JSON.parse(localStorage.getItem(FILTROS_TABELAS), this._reviver);
    if (mapFilter !== null) {
      mapFilter.delete(rota);
      localStorage.setItem(FILTROS_TABELAS, JSON.stringify(mapFilter, this._replacer));
    }
  }

  recoverFilter(rota: string) {
    return this.getFiltro(rota);
  }

  saveFilter(rota: string, limit: number, offset: number, ordem: string, query: string, otherFilters: any[], selection: any[]) {
    const filter: FilterTable = {
      limit: limit,
      order: ordem,
      offset: offset,
      query: query,
      otherFilters: otherFilters,
      selection: selection
    };
    this.saveFiltro(rota, filter);
  }

  private _reviver(key: string, value: any) {
    if (typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
  }

  private _replacer(key: string, value: any) {
    if (value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries())
      };
    } else {
      return value;
    }
  }
}
