import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '@core/services';
import { DeleteDialog } from '@shared/components';
import { FileValidators } from 'ngx-file-drag-drop';

@Component({
  selector: 'input-file-component',
  templateUrl: './input-file.component.html'
})
export class InputFileComponent implements OnInit {
  @Input() id: string;
  @Input() disabled = false;
  @Input() submitted = false;
  @Input() required = false;
  @Input() isOnlyView = false;
  @Input() arquivoAtual: string;

  /**
   * Não pode ser maior do que isso, pois o nginx já está nesse limite
   * acarretaria em erro 413 com status 0
   */
  @Input() maxSizeMB = 30;

  @Output() handleSetNewFile = new EventEmitter();
  @Output() handleDownloadCurrentFile = new EventEmitter();
  @Output() handleRemoveCurrentFile = new EventEmitter();

  fileControl: FormControl;

  constructor(private readonly messageService: MessageService, private readonly dialog: MatDialog) {}

  ngOnInit(): void {
    this.fileControl = new FormControl({ value: [], disabled: this.disabled }, []);

    if (this.required) {
      this.fileControl.addValidators(FileValidators.required);
    }
  }

  _handleSetFile(files: File[]) {
    const file = files[0];
    if (file) {
      const sizeMb = file.size / 1024 / 1024;
      if (sizeMb > this.maxSizeMB) {
        this.messageService.showAlert('Arquivo não anexado', 'Tamanho máximo permitido para anexo é de ' + this.maxSizeMB + ' MB.');
        this.fileControl.setValue([]);
      } else {
        this.handleSetNewFile.emit(files);
      }
    }
  }

  _handleRemoveCurrentFile() {
    const dialogRef = this.dialog.open(DeleteDialog, {
      data: { title: 'Excluir Arquivo Atual do Sonolog', message: 'Tem certeza que deseja excluir? Você poderá adicionar outro arquivo.' }
    });

    dialogRef.afterClosed().subscribe((isAgreedToDelete) => {
      if (isAgreedToDelete) {
        this.handleRemoveCurrentFile.emit();
      }
    });
  }

  _handleDownloadCurrentFile() {
    this.handleDownloadCurrentFile.emit();
  }
}
