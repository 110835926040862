export enum ERoutes {
  PATH_AUTH = 'auth',
  PATH_PRIVATE = '',
  LOGIN = 'login',
  PT = 'pt',
  APR = 'apr',
  RECUPERAR_ACESSO = 'recuperar-acesso',
  ALTERAR_SENHA = 'alterar-senha',
  HOME = 'home',
  EMPRESA = 'empresa',
  CARGO = 'cargo',
  CAMPO = 'campo',
  PERFIL = 'perfil',
  CLUSTER = 'cluster',
  COLABORADOR = 'colaborador',
  USUARIO = 'usuario',
  CRIAR = 'criar',
  IMPORTAR = 'importar',
  EDITAR = 'editar',
  DETALHAR = 'detalhar',
  VALIDAR = 'validar',
  ENCERRAR = 'encerrar',
  DIAGNOSTICAR = 'diagnosticar',
  INSTALAR = 'instalar',
  REMOVER = 'remover'
}
