<button type="button" class="base-btn bg-neutro-400 text-white"
        [matMenuTriggerFor]="menu"
        title="Exportar"
        [disabled]="true">
  <span class="material-icons">download</span>Exportar
  <mat-icon class="h-6 w-6" *ngIf="isDownloading"
    ><mat-spinner class="icon-snniper-export-table" color="primary" [diameter]="20"></mat-spinner
  ></mat-icon>
</button>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="download(EtypeFile.CSV)"><span class="text-base">CSV</span></button>
</mat-menu>
