export enum EPermissions {
  // CRUD USUÁRIO
  ROLE_CADASTRO_USUARIO_CADASTRAR = 'ROLE_CADASTRO_USUARIO_CADASTRAR',
  ROLE_CADASTRO_USUARIO_EDITAR = 'ROLE_CADASTRO_USUARIO_EDITAR',
  ROLE_CADASTRO_USUARIO_LISTAR = 'ROLE_CADASTRO_USUARIO_LISTAR',
  ROLE_CADASTRO_USUARIO_APAGAR = 'ROLE_CADASTRO_USUARIO_APAGAR',
  ROLE_CADASTRO_USUARIO_LOTE = 'ROLE_CADASTRO_USUARIO_LOTE',

  // CRUD PERMISSOES
  ROLE_CADASTRO_PERFIL_CADASTRAR = 'ROLE_CADASTRO_PERFIL_CADASTRAR',
  ROLE_CADASTRO_PERFIL_EDITAR = 'ROLE_CADASTRO_PERFIL_EDITAR',
  ROLE_CADASTRO_PERFIL_LISTAR = 'ROLE_CADASTRO_PERFIL_LISTAR',
  ROLE_CADASTRO_PERFIL_APAGAR = 'ROLE_CADASTRO_PERFIL_APAGAR',

  // CRUD CARGO
  ROLE_CADASTRO_CARGO_CADASTRAR = 'ROLE_CADASTRO_CARGO_CADASTRAR',
  ROLE_CADASTRO_CARGO_EDITAR = 'ROLE_CADASTRO_CARGO_EDITAR',
  ROLE_CADASTRO_CARGO_LISTAR = 'ROLE_CADASTRO_CARGO_LISTAR',
  ROLE_CADASTRO_CARGO_APAGAR = 'ROLE_CADASTRO_CARGO_APAGAR',

  // CRUD DE AREA
  ROLE_CADASTRO_AREA_CADASTRAR = 'ROLE_CADASTRO_AREA_CADASTRAR',
  ROLE_CADASTRO_AREA_EDITAR = 'ROLE_CADASTRO_AREA_EDITAR',
  ROLE_CADASTRO_AREA_LISTAR = 'ROLE_CADASTRO_AREA_LISTAR',
  ROLE_CADASTRO_AREA_APAGAR = 'ROLE_CADASTRO_AREA_APAGAR',

  // CRUD DE EMPRESA
  ROLE_CADASTRO_EMPRESA_CADASTRAR = 'ROLE_CADASTRO_EMPRESA_CADASTRAR',
  ROLE_CADASTRO_EMPRESA_EDITAR = 'ROLE_CADASTRO_EMPRESA_EDITAR',
  ROLE_CADASTRO_EMPRESA_LISTAR = 'ROLE_CADASTRO_EMPRESA_LISTAR',
  ROLE_CADASTRO_EMPRESA_APAGAR = 'ROLE_CADASTRO_EMPRESA_APAGAR',

  // CRUD DE DOCUMENTO
  ROLE_CADASTRO_DOCUMENTO_CADASTRAR = 'ROLE_CADASTRO_DOCUMENTO_CADASTRAR',
  ROLE_CADASTRO_DOCUMENTO_EDITAR = 'ROLE_CADASTRO_DOCUMENTO_EDITAR',
  ROLE_CADASTRO_DOCUMENTO_LISTAR = 'ROLE_CADASTRO_DOCUMENTO_LISTAR',
  ROLE_CADASTRO_DOCUMENTO_APAGAR = 'ROLE_CADASTRO_DOCUMENTO_APAGAR',

  // CRUD DE PESSOA
  ROLE_CADASTRO_PESSOA_CADASTRAR = 'ROLE_CADASTRO_PESSOA_CADASTRAR',
  ROLE_CADASTRO_PESSOA_EDITAR = 'ROLE_CADASTRO_PESSOA_EDITAR',
  ROLE_CADASTRO_PESSOA_LISTAR = 'ROLE_CADASTRO_PESSOA_LISTAR',
  ROLE_CADASTRO_PESSOA_APAGAR = 'ROLE_CADASTRO_PESSOA_APAGAR',

  // CRUD DE CAMPO
  ROLE_CADASTRO_CAMPO_CADASTRAR = 'ROLE_CADASTRO_CAMPO_CADASTRAR',
  ROLE_CADASTRO_CAMPO_EDITAR = 'ROLE_CADASTRO_CAMPO_EDITAR',
  ROLE_CADASTRO_CAMPO_LISTAR = 'ROLE_CADASTRO_CAMPO_LISTAR',
  ROLE_CADASTRO_CAMPO_APAGAR = 'ROLE_CADASTRO_CAMPO_APAGAR',

  // CRUD DE APR
  ROLE_CADASTRO_APR_APROVAR = 'ROLE_CADASTRO_AST_APROVAR',
  ROLE_CADASTRO_APR_CADASTRAR = 'ROLE_CADASTRO_AST_CADASTRAR',
  ROLE_CADASTRO_APR_EDITAR = 'ROLE_CADASTRO_AST_EDITAR',
  ROLE_CADASTRO_APR_LISTAR = 'ROLE_CADASTRO_AST_LISTAR',
  ROLE_CADASTRO_APR_APAGAR = 'ROLE_CADASTRO_AST_APAGAR',

  // CRUD DE PT
  ROLE_CADASTRO_PT_CADASTRAR = 'ROLE_CADASTRO_PT_CADASTRAR',
  ROLE_CADASTRO_PT_EDITAR = 'ROLE_CADASTRO_PT_EDITAR',
  ROLE_CADASTRO_PT_LISTAR = 'ROLE_CADASTRO_PT_LISTAR',
  ROLE_CADASTRO_PT_APAGAR = 'ROLE_CADASTRO_PT_APAGAR'
}
