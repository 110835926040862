import { Component, Input } from '@angular/core';
import { EFileType } from '@core/enums/e-file-type';
import { CrudErrorService } from '@core/services';
import { DownloadFileService } from '@core/services/download-file.service';
import { MessageService } from '@core/services/message.service';

@Component({
  selector: 'app-export-table',
  templateUrl: './export-table.component.html',
  styleUrls: ['./export-table.style.scss']
})
export class ExportTableComponent {
  @Input() fileName?: string;
  @Input() path: string;
  @Input() pathCustomized?: boolean = false;
  @Input() params?: Map<string, string>;

  isDownloading = false;
  EtypeFile = EFileType;

  constructor(private service: DownloadFileService, private messageService: MessageService, private errorService: CrudErrorService) {}

  download(type: EFileType) {
    this.isDownloading = true;
    this.service.downloadFileDefault(this.path, this.pathCustomized, this.params).subscribe({
      next: (value) => {
        this.service.saveFile(this.fileName, type, value.blob);
      },
      error: (err) => {
        this.isDownloading = false;
        this.errorService.handleErrorAPI(err, 'Erro ao baixar arquivo');
      },
      complete: () => {
        this.isDownloading = false;
        this.messageService.showSuccess('', 'Arquivo baixado com sucesso');
      }
    });
  }
}
