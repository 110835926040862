<div class="my-2 flex flex-col rounded" xmlns="http://www.w3.org/1999/html">
  <div class="mb-4 flex w-full items-start justify-between xs:flex-col">
    <div class="w-4/12 xs:mb-4 xs:w-full sm:w-8/12 md:w-8/12 lg:w-6/12">
      <form class="flex w-full items-start justify-start gap-4" (ngSubmit)="_handleFetchByQuery()">
        <div class="input-wrapper flex items-center gap-2 border-2 border-gray-400" *ngIf="showPesquisa">
          <span class="material-icons text-primario-700">search</span>
          <input
            placeholder="Pesquisar"
            type="text"
            class="input-base"
            [(ngModel)]="query"
            id="pesquisar"
            name="pesquisar"
            autocomplete="off"
          />
          <button
            type="button"
            title="Limpar seleção"
            *ngIf="query"
            (click)="handleClearQuery()"
            class="flex items-center rounded bg-neutro-100 p-1 text-xs transition-all duration-150 hover:bg-gray-200"
          >
            <span class="material-icons text-black">close</span>
          </button>
        </div>
        <button
          *ngIf="showPesquisa"
          title="Pesquisar"
          type="submit"
          [disabled]="!isSearching && !query"
          class="flex flex-row items-center justify-center gap-4 rounded px-6 py-2.5 text-branco"
          [ngClass]="!isSearching && !query ? 'bg-gray-500' : 'bg-primario-700 hover:opacity-90'"
        >
          <span class="material-icons">search</span>
        </button>
        <button
          *ngIf="showFiltro"
          [title]="isFilterEnable ? 'Desabilitar Filtro por Coluna' : 'Habilitar Filtro por Coluna'"
          type="button"
          (click)="handleEnableFilterColumns()"
          class="flex flex-row items-center justify-center gap-4 rounded bg-primario-700 px-6 py-2.5 text-branco hover:opacity-90"
        >
          <span *ngIf="isFilterEnable" class="material-icons text-branco">filter_alt_off</span>
          <span *ngIf="!isFilterEnable" class="material-icons text-branco">filter_alt</span>
        </button>
        <button
          *ngIf="showFiltro"
          title="Limpar Filtros"
          type="button"
          (click)="_handleClearFilters()"
          class="flex flex-row items-center justify-center gap-4 rounded bg-primario-700 px-6 py-2.5 text-branco disabled:bg-gray-500 hover:opacity-90"
        >
          <span class="material-icons">delete_sweep</span>
        </button>
      </form>
    </div>

    <div class="flex gap-4">
      <button
        [disabled]="hasBadgeImport === false || badgeImport > 0 ? false : true"
        *ngIf="hasImport"
        (click)="_handleImport()"
        type="button"
        [title]="
          hasBadgeImport === false || badgeImport > 0
            ? titleButtonImport
              ? titleButtonImport
              : 'Importar'
            : 'Não existem medições para importar.'
        "
        class="base-btn bg-primario-700 text-branco disabled:bg-gray-500 hover:opacity-90"
        color="primary"
        [matBadge]="badgeImport"
        matBadgePosition="before"
        matBadgeColor="accent"
      >
        <span class="material-icons">upload_file</span>
        {{ titleButtonImport ? titleButtonImport : 'Importar' }}
      </button>
      <app-export-table
        *ngIf="hasExportFile"
        [fileName]="fileNameExport"
        [path]="pathExport"
        [params]="paramsToExportFile"
        [pathCustomized]="pathExportCustomized"
      ></app-export-table>
      <button
        *ngIf="hasCreate"
        (click)="_handleCreate()"
        type="button"
        [title]="titleButtonCreate ? titleButtonCreate : 'Inserir'"
        class="base-btn bg-primario-700 text-branco"
      >
        <span class="material-icons">add</span>
        {{ titleButtonCreate ? titleButtonCreate : 'Inserir' }}
      </button>
    </div>
  </div>

  <div class="max-h-[63vh] overflow-x-auto overflow-y-auto pb-2 scrollbar-thin scrollbar-track-neutro-100 scrollbar-thumb-gray-300">
    <div *ngIf="isLoading; else showTable">
      <mat-spinner></mat-spinner>
    </div>

    <ng-template #showTable>
      <table
        mat-table
        matSort
        [matSortDisabled]="isSortDisable"
        [dataSource]="data"
        [matSortActive]="_handleCheckSortActive()"
        [matSortDirection]="_handleCheckSortDirection()"
        (matSortChange)="_handleSort($event)"
        class="relative w-full"
      >
        <ng-container *ngFor="let headerOption of headerOptions" [matColumnDef]="headerOption.attribute">
          <th
            mat-header-cell
            *matHeaderCellDef
            [mat-sort-header]="headerOption.attribute"
            arrowPosition="after"
            class="bg-neutro-100 text-center text-base font-bold"
          >
            <p class="text-center">{{ headerOption.description }}</p>
          </th>

          <ng-container *ngIf="headerOption.type">
            <td mat-cell class="text-center text-base" *matCellDef="let element">
              <div
                [innerHTML]="
                  headerOption?.type && headerOption?.isToFormatValue === false && element[headerOption.attribute] !== null
                    ? element[headerOption.attribute]
                    : headerOption?.type === 'NUMBER' && element[headerOption.attribute] !== null && element[headerOption.attribute] !== ''
                    ? (element[headerOption.attribute] | number : headerOption?.digitsInfo ?? digitsInfoDefault : 'pt')
                    : headerOption?.type === 'DATE_TIME_MILLISECONDS' && element[headerOption.attribute]
                    ? (element[headerOption.attribute] | date : 'dd/MM/yyyy HH:mm:ss.SSS')
                    : headerOption?.type === 'DATE_TIME' && element[headerOption.attribute]
                    ? (element[headerOption.attribute] | date : 'dd/MM/yyyy HH:mm' : 'GMT-3')
                    : headerOption?.type === 'DATE' && element[headerOption.attribute]
                    ? (element[headerOption.attribute] | date : 'dd/MM/yyyy')
                    : headerOption?.type === 'TIME' && element[headerOption.attribute]
                    ? element[headerOption.attribute]
                    : '-'
                "
              ></div>
            </td>
          </ng-container>

          <ng-container *ngIf="!headerOption.type">
            <td mat-cell class="text-center text-base" *matCellDef="let element">
              <div [innerHTML]="element[headerOption.attribute] ? element[headerOption.attribute] : '-'"></div>
            </td>
          </ng-container>
        </ng-container>

        <ng-container *ngFor="let searchOption of headerOptionsAttributeSearch" [matColumnDef]="searchOption.description">
          <th
            class="items-center bg-neutro-100 p-2 text-center text-base"
            [ngClass]="{ 'top-[109px]': isHeaderLarge, 'top-[56px]': !isHeaderLarge }"
            mat-header-cell
            *matHeaderCellDef
          >
            <input-filter
              *ngIf="searchOption.typeFilter !== 'SELECTOR_CHECKBOX'"
              [filtrosCache]="filterColumns"
              [valueType]="searchOption?.typeValue"
              [attribute]="searchOption?.attribute"
              (fieldToSearch)="handleApplyFilterColumn($event)"
              (fieldToDelete)="handleCleanFilterColumn($event)"
            ></input-filter>

            <selector-checkbox-filter
              *ngIf="searchOption.typeFilter === 'SELECTOR_CHECKBOX'"
              [filtrosCache]="filterColumns"
              [attributeSelectorCheckbox]="searchOption.attributeSelectorCheckbox"
              [route]="searchOption?.route"
              (fieldToSearch)="handleApplyFilterColumn($event)"
              (fieldToDelete)="handleCleanFilterColumn($event)"
            ></selector-checkbox-filter>
          </th>
        </ng-container>

        <ng-container [matColumnDef]="'inicio'">
          <th mat-header-cell *matHeaderCellDef class="bg-neutro-100 text-lg"></th>
          <td mat-cell *matCellDef="let element"></td>
        </ng-container>

        <ng-container [matColumnDef]="'checkbox'">
          <th mat-header-cell *matHeaderCellDef class="absolute bg-neutro-100">
            <mat-checkbox
              color="primary"
              (change)="$event ? toggleAllRows() : null"
              [checked]="selectionCheckbox.hasValue() && isAllSelected()"
              [indeterminate]="selectionCheckbox.hasValue() && !isAllSelected()"
              [title]="isAllSelected() ? 'Selecionar Todos' : 'Remover Todos'"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              color="primary"
              [style.zindex]=""
              (click)="$event.stopPropagation()"
              (change)="$event ? toggleOneRow(row) : null"
              [checked]="selectionCheckbox.isSelected(row)"
              [title]="selectionCheckbox.isSelected(row) ? 'Selecionar' : 'Remover'"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'acoes'">
          <th mat-header-cell *matHeaderCellDef class="bg-neutro-100"></th>
          <td mat-cell *matCellDef="let element">
            <div class="flex max-h-28 max-w-5xl items-center">
              <button
                *ngIf="hasOtherAction"
                (click)="_handleOtherAction(element)"
                [title]="titleButtonOtherAction ? titleButtonOtherAction : 'Validar'"
                type="button"
                [disabled]="element.isDisableOtherAction"
                [ngClass]="
                  element.isDisableOtherAction
                    ? 'button-icon-disable'
                    : colorButtonOtherAction
                    ? 'button-icon text-' + colorButtonOtherAction
                    : 'button-icon text-primario-700'
                "
              >
                <span class="material-icons text-xl">{{ iconButtonOtherAction || 'assignment_turned_in' }}</span>
              </button>
              <button
                *ngIf="hasValidate"
                (click)="_handleValidate(element.id)"
                [title]="titleButtonValidate ? titleButtonValidate : 'Validar'"
                type="button"
                [disabled]="element.isDisableValidate"
                [ngClass]="
                  element.isDisableValidate
                    ? 'button-icon-disable'
                    : element.pronto_validacao === 'Sim'
                    ? 'button-icon text-green-600'
                    : element.pronto_validacao === 'Não'
                    ? 'button-icon text-orange-400'
                    : colorButtonValidate
                    ? 'button-icon text-' + colorButtonValidate
                    : 'button-icon text-primario-700'
                "
              >
                <span class="material-icons text-xl">{{ iconButtonValidate || 'assignment_turned_in' }}</span>
              </button>
              <button
                *ngIf="hasDetail"
                (click)="_handleDetail(element.id)"
                [title]="titleButtonDetail ? titleButtonDetail : 'Visualizar'"
                type="button"
                class="button-icon text-primario-700"
              >
                <span class="material-icons text-xl">find_in_page</span>
              </button>
              <button
                *ngIf="hasEdit"
                (click)="_handleEdit(element.id)"
                [title]="titleButtonEdit ? titleButtonEdit : 'Editar'"
                type="button"
                [disabled]="element.isDisableEdit"
                [ngClass]="element.isDisableEdit ? 'button-icon-disable' : 'button-icon text-warning'"
              >
                <span class="material-icons text-xl">edit</span>
              </button>
              <button
                *ngIf="false"
                (click)="_handleDelete(element.id)"
                [title]="titleButtonDelete ? titleButtonDelete : 'Deletar'"
                type="button"
                [disabled]="element.isDisableDelete"
                [ngClass]="element.isDisableDelete ? 'button-icon-disable' : 'button-icon text-danger'"
              >
                <span class="material-icons text-xl">delete</span>
              </button>
              <button
                *ngIf="
                  element.display_info_criacao === null ||
                  element.display_info_criacao ||
                  element.display_info_alteracao === null ||
                  element.display_info_alteracao ||
                  element.display_info_validacao === null ||
                  element.display_info_validacao ||
                  element.display_info_encerramento === null ||
                  element.display_info_encerramento
                "
                [matMenuTriggerFor]="matMenuInfoData"
                title="Informações"
                type="button"
                class="button-icon text-primario-700"
              >
                <span class="material-icons text-xl">info</span>
              </button>
              <mat-menu #matMenuInfoData="matMenu" class="p-2">
                <p *ngIf="element.display_info_criacao === null || element.display_info_criacao" class="text-sm">
                  Criação:
                  <span class="font-semibold"> {{ element.display_info_criacao === null ? '-' : element.display_info_criacao }}</span>
                </p>
                <p *ngIf="element.display_info_alteracao === null || element.display_info_alteracao" class="text-sm">
                  Alteração:
                  <span class="font-semibold"> {{ element.display_info_alteracao === null ? '-' : element.display_info_alteracao }}</span>
                </p>
                <p *ngIf="element.display_info_validacao === null || element.display_info_validacao" class="text-sm">
                  Validação:
                  <span class="font-semibold"> {{ element.display_info_validacao === null ? '-' : element.display_info_validacao }} </span>
                </p>
                <p *ngIf="element.display_info_encerramento === null || element.display_info_encerramento" class="text-sm">
                  Encerramento:
                  <span class="font-semibold">
                    {{ element.display_info_encerramento === null ? '-' : element.display_info_encerramento }}</span
                  >
                </p>
                <p *ngIf="element.fers_disponivel !== undefined" class="text-sm">
                  FE&RS disponível:
                  <span class="font-semibold"> {{ element.fers_disponivel === null ? '-' : element.fers_disponivel }}</span>
                </p>
                <p *ngIf="element.bsw_disponivel !== undefined" class="text-sm">
                  Bsw disponível:
                  <span class="font-semibold"> {{ element.bsw_disponivel === null ? '-' : element.bsw_disponivel }}</span>
                </p>
                <p *ngIf="element.cromatografia_disponivel !== undefined" class="text-sm">
                  Cromatografia disponível:
                  <span class="font-semibold">
                    {{ element.cromatografia_disponivel === null ? '-' : element.cromatografia_disponivel }}</span
                  >
                </p>
                <p *ngIf="element.densidade_disponivel !== undefined" class="text-sm">
                  Densidade disponível:
                  <span class="font-semibold"> {{ element.densidade_disponivel === null ? '-' : element.densidade_disponivel }}</span>
                </p>
                <p *ngIf="element.instrumento_disponivel !== undefined" class="text-sm">
                  Instrumentos disponíveis:
                  <span class="font-semibold"> {{ element.instrumento_disponivel === null ? '-' : element.instrumento_disponivel }}</span>
                </p>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'checkboxSearch'">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="bg-neutro-100 text-lg"
            [ngClass]="{ 'top-[109px]': isHeaderLarge, 'top-[56px]': !isHeaderLarge }"
          ></th>
          <td mat-cell *matCellDef="let element"></td>
        </ng-container>

        <ng-container [matColumnDef]="'comentarios'">
          <th mat-header-cell *matHeaderCellDef class="bg-neutro-100 text-center text-base font-bold">Comentários</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex max-h-28 max-w-5xl items-center justify-center">
              <button
                *ngIf="hasColumnComentarios"
                [matMenuTriggerFor]="matMenuInfoDataComentarios"
                title="Comentários"
                type="button"
                [ngClass]="element.comentarios ? 'button-icon text-primario-700' : 'button-icon-disable'"
                [disabled]="!element.comentarios"
              >
                <span class="material-icons text-xl">message</span>
              </button>
              <mat-menu #matMenuInfoDataComentarios="matMenu" class="p-2">
                <div [innerHTML]="element.comentarios"></div>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'inicioSearch'">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="bg-neutro-100 text-lg"
            [ngClass]="{ 'top-[109px]': isHeaderLarge, 'top-[56px]': !isHeaderLarge }"
          ></th>
          <td mat-cell *matCellDef="let element"></td>
        </ng-container>

        <ng-container [matColumnDef]="'acoesSearch'">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="bg-neutro-100 text-lg"
            [ngClass]="{ 'top-[109px]': isHeaderLarge, 'top-[56px]': !isHeaderLarge }"
          ></th>
          <td mat-cell *matCellDef="let element"></td>
        </ng-container>

        <ng-container [matColumnDef]="'comentariosSearch'">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="bg-neutro-100 text-lg"
            [ngClass]="{ 'top-[109px]': isHeaderLarge, 'top-[56px]': !isHeaderLarge }"
          ></th>
          <td mat-cell *matCellDef="let element"></td>
        </ng-container>

        <ng-container [matColumnDef]="'fimSearch'">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="bg-neutro-100 text-lg"
            [ngClass]="{ 'top-[109px]': isHeaderLarge, 'top-[56px]': !isHeaderLarge }"
          ></th>
          <td mat-cell *matCellDef="let element"></td>
        </ng-container>

        <ng-container [matColumnDef]="'fim'">
          <th mat-header-cell *matHeaderCellDef class="bg-neutro-100 text-lg"></th>
          <td mat-cell *matCellDef="let element"></td>
        </ng-container>

        <tr
          id="headerRow"
          [style.height]="isHeaderLarge ? '109px' : '56px'"
          class="sticky z-10"
          #headerRow
          mat-header-row
          *matHeaderRowDef="_headerOptionsAttributes; sticky: true"
        ></tr>

        <tr
          class="transition-all duration-700"
          [hidden]="!isFilterEnable"
          mat-header-row
          *matHeaderRowDef="_headerOptionsAttributesSearch; sticky: true"
        ></tr>

        <tr
          mat-row
          *matRowDef="let row; columns: _headerOptionsAttributes"
          (click)="_handleOnClickRow(row)"
          class="transition-all duration-150 hover:bg-slate-100"
        ></tr>
        <div class="mat-row flex items-center p-2">
          <td *matNoDataRow [colSpan]="headerOptions.length" class="mat-cell">Nenhum dado encontrado</td>
        </div>
      </table>
    </ng-template>
  </div>

  <div class="w-full">
    <div *ngIf="subtitleFooter && !isLoading">
      <span>{{ subtitleFooter }}</span>
    </div>

    <mat-paginator
      class="mt-4"
      *ngIf="!isPaginateDisable"
      [length]="totalItems"
      [pageSize]="pageSize"
      (page)="_handlePageEvent($event)"
      [pageIndex]="paginaAtual"
      [pageSizeOptions]="pageOptions"
    ></mat-paginator>
  </div>
</div>
